import React from 'react';
import {AuthRoute} from './AuthRoute';
import {routesSource} from './RoutesSource';
import {RouteWrapper} from './RouteWrapper';
import {Redirect, Router, Switch} from 'react-router-dom';
import {history} from '../utilities';

const routerConfig = () => {
    return (
    <Router history={history}>
        <Switch>
          {routesSource.map((item) => {
            if (item?.child?.length) {
              return item.child.map((childItem) => {
                return (
                  <RouteWrapper
                    key={item?.key}
                    path={`${childItem?.path}`}
                    exact={childItem?.exact}
                    component={childItem?.component}
                    private={childItem?.private}
                  />
                );
              });
            }
            return (
              <AuthRoute
                key={item?.key}
                path={item?.path}
                private={item?.private}
                exact={item?.exact}
                component={item?.component}
                title={item.title}
              />
            );
          })}
          <Redirect to="/home" />
        </Switch>
    </Router>
    )
}

export default routerConfig