import Axios from 'axios'
import {withRouter} from 'react-router-dom'
import { useCookies } from 'react-cookie'

const Post = async(path, request) => {
    const [cookies] = useCookies(['udata']);
    const {userId, username} = cookies.udata;

    return await Axios({
        method: "POST",
        baseURL: process.env.REACT_APP_MAIN_API,
        url: path,
        data: request ?? {},
        headers: {
            "Content-Type": "application/json",
            userid: userId,
            username: username
        },
        timeout: 10000
    }).then(({data}) => {
        return data
    }).catch((err) => {
        console.log(err)
        return err        
    })
}

const Get = (path, params) => {
    const [cookies] = useCookies(['udata']);
    const {userId, username} = cookies.udata;

    const promise = new Promise((resolve, reject) => {
        Axios({
            method: "GET",
            baseURL: process.env.REACT_APP_MAIN_API,
            // baseURL: 'https://dfa97460-c3b2-41aa-8b95-e50cbfad918c.mock.pstmn.io/api',
            url: path,
            params: params ?? {},
            headers: {
                "Content-Type": "application/json",
                userid: userId,
                username: username
            },
            timeout: 10000
        }).then(({data}) => {
            console.log("RESPONSE")
            resolve(data)
        }).catch((err) => {
            reject(err.message)
        })
    })
    return promise
}

const Services = {
    Post,
    Get
}

export default withRouter(Services)