import useSWR from "swr";
import { useCookies } from 'react-cookie';

export function useRequest(path, options, payload) {
  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;

  const fetcher = (...args) => fetch(...args, {
    headers: {
      "Content-Type": "application/json",
      userid: userId,
      username: username,
    },
  }).then((res) => res.json());

  const apiURL = process.env.REACT_APP_MAIN_API;

  const { data, error, mutate } = useSWR(`${apiURL}${path}`, fetcher, options);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    refetch: mutate,
  };
}
