import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, List, Avatar } from 'antd';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useRequest } from '../../hooks/useRequest';

const FormInfo = () => {
  const [cookies] = useCookies(['udata']);
  const { userId, username } = cookies.udata;
  const { data: info, refetch } = useRequest('/merchants/info');
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const info = values.users.map((item) => item.info);
    const data = await axios.post(
      `${process.env.REACT_APP_MAIN_API}/merchants/info`,
      {
        info,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          userid: userId,
          username,
        },
      },
    );

    if (data.status < 300) {
      refetch();
    }
  };

  const onClickDelete = async (item) => {
    console.log(item);
    const data = await axios.delete(
      `${process.env.REACT_APP_MAIN_API}/merchants/info?id=${item.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          userid: userId,
          username,
        },
      },
    );

    if (data.status < 300) {
      form.setFieldsValue({
        users: [],
      })
      refetch();
    }

    console.log({ data });
  };

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={info?.data?.info ?? []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button onClick={() => onClickDelete(item)} key="list-loadmore-edit">
                Delete
              </Button>,
            ]}
          >
            <List.Item.Meta title={item?.description} />
          </List.Item>
        )}
      />

      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
        autoComplete="off"
        // initialValues={{
        //   users: [
        //     {
        //       info: 'info 1',
        //     },
        //   ],
        // }}
      >
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'info']}
                    rules={[
                      {
                        required: true,
                        message: 'info is required',
                      },
                      {
                        max: 120,
                        message: 'info is too long, max 120 char',
                      }
                    ]}
                  >
                    <Input.TextArea placeholder="New Info" style={{ width: '40rem' }} />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormInfo;
