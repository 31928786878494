import React, {useState, useEffect} from 'react'
import {
    Card, 
    ListGroup, 
    ListGroupItem,
    Badge
} from 'react-bootstrap'
import DefaultUserIcon from '../../assets/logo/default-user.png'

const ProfileCard = (props) => {
    const [userData, setuserData] = useState({})

    useEffect(() => {
        setuserData(props.userData)
        console.log(props.userData);
        return () => {
            setuserData({})
        }
    }, [props.userData])

    return (
        <Card >
        <Card.Img variant="top" src={userData?.foto_usaha?.[0] ?? DefaultUserIcon}/>
        <Card.Body>
            <Card.Title>
                {userData?.nama_mitra ?? "NO DATA"}
                {' '}
                {userData?.status_approve_mitra === null && <Badge variant="primary">New</Badge>}
                {userData?.status_approve_mitra === 1 && <Badge variant="success">Aktif</Badge>}
                {userData?.status_approve_mitra === 2 && <Badge variant="danger">Non Aktif</Badge>}
                {userData?.status_approve_mitra === 3 && <Badge variant="warning">Menunggu Approval</Badge>}
            </Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush">
            <ListGroupItem><b>Pemilik: </b>{userData?.nama_pemilik ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Member ID: </b>{userData?.memberid_kanvaser ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Jenis Mitra Usaha: </b>{userData?.jenis_usaha ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Alamat: </b>{`${userData?.alamat_usaha} ${userData?.kota_usaha}` ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Phone: </b>{userData?.no_hp ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Hari Kerja: </b>{userData?.keterangan_tempat_usaha?.hari_kerja ?? "NO DATA"}</ListGroupItem>
            <ListGroupItem><b>Jam Operasional: </b>{`${userData?.keterangan_tempat_usaha?.jam_buka} - ${userData?.keterangan_tempat_usaha?.jam_tutup}` ?? "NO DATA"}</ListGroupItem>
        </ListGroup>
        </Card>
    )
}

export default ProfileCard