import React, {useState} from 'react'
import { Card, Form, Button, Alert, Spinner, Container } from 'react-bootstrap'
import {Topbar, Footer} from '../../components'
import Axios from 'axios'
import { Modal } from "antd";
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import Services from '../../services'

const Login = () => {
    const [alert, setalert] = useState(0)
    const [uname, setuname] = useState("")
    const [upass, setupass] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const [cookies, setCookie] = useCookies(['udata'])

    let history = useHistory()

    const error  = (content) => {
        Modal.error ({
            title:content,
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault()
        setisLoading(true)
        let config = {
            method: 'POST',
            url: `${process.env.REACT_APP_MAIN_API}/login/fusionAuth`,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                username: uname,
                password: upass
            },
            timeout: 30000
        }

        setisLoading(false)
        // setCookie('udata', {token: "1111111111", userId: "admin", username: "Admin"}, { path: '/', sameSite: true })
        // history.push('/home')
    
        Axios(config).then(async ({data}) => {
            let resp = data.data
            if(data.statusCode === '000' ){
                setisLoading(false)
                setCookie('udata', resp, { path: '/', sameSite: true })
                history.push('/home')
            }else{

                error('Invalid Credential, Username or Password wrong !')
                // setisLoading(false)
                // setupass("")
                // setalert(1)
                // setTimeout(() => {
                //     setalert(0)
                // }, 4000);
            }

        }).catch((err) => {
            console.log('FA ERROR',err)
            error('Invalid Credential, Username or Password wrong !')

            // setalert(2)
            // setisLoading(false)
            // setTimeout(() => {
            //     setalert(0)
            // }, 4000);
            console.log(err.message)
        })
    }

    return (
        <div style={{minHeight: '100vh', backgroundColor: 'whiteSmoke'}}>
            <Topbar/>
            <Container style={{minHeight: '100vh', backgroundColor: 'whiteSmoke', marginTop: "8rem"}}>
            <Card style={{width: "30rem", margin: "0 auto"}}>
            <Card.Header className="text-center"><h5><b>LOGIN</b></h5></Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    {alert === 1 && <Alert style={{ marginTop: "10px" }} variant="danger">Username / Password Salah!</Alert>}
                    {alert === 2 && <Alert style={{ marginTop: "10px" }} variant="danger">Server Error, Silahkan Coba Beberapa Saat Lagi!</Alert>}
                    <Form.Group>
                    <Form.Control type="text" placeholder="Enter Username" onChange={(e) => setuname(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                    <Form.Control type="password" placeholder="*********" onChange={(e) => setupass(e.target.value)}/>
                    {alert === 1 && <Card.Text className="text-danger">Please re-enter password</Card.Text>}
                    </Form.Group>
                    { isLoading ? (
                        <Button variant="danger" disabled block>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                        </Button>
                    ) : (
                        <Button variant="danger" type="submit" block>LOGIN</Button>
                    )}
                </Form>
            </Card.Body>
            <Card.Footer className="text-muted"></Card.Footer>
            </Card>
            </Container>
            <Footer />
        </div>
    )
}

export default Login