import {
  Button,
  Form,
  Input,
  Checkbox,
  Col,
  Row,
  Space,
  Select,
  message,
  Upload,
  TimePicker,
  Image,
  Modal,
  Spin
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { useMerchant } from "../../hooks/useMerchant";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import warning from '../../assets/warning.png';
import { useCookies } from 'react-cookie';

const { Option, OptGroup } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Tab3 = ({onClickedNext, registerParam, merchantDetail, zonid, onPrevious}) => {

  //Standarize Phone
  if(merchantDetail?.merchantOwnerPhone){
    if(merchantDetail.merchantOwnerPhone.charAt(0) == "0") merchantDetail.merchantOwnerPhone.replace("0","")
    merchantDetail.merchantOwnerPhone = merchantDetail.merchantOwnerPhone.replace("+62","")
  }

  const { data: provinsiData } = useRequest(
    "/config/locationDetail?idProvinsi=ALL"
  );

  const { data: bankData } = useRequest(
    "/config/bank"
  );

  const [jenisUsaha, setjenisUsaha] = useState();
  const [loading, setLoading] = useState(false);
  const [fotoSekitar, setFotoSekitar] = useState();
  const [fotoDepan, setFotoDepan] = useState();
  const [petugasAkuisisi, setPetugasAkuisisi] = useState();
  const [pemilik, setPemilik] = useState();
  const [spin, setSpin] = useState(false);
  const [disable, setDisable] = useState(true);
  const [kabupatenList, setKabupatenList] = useState([]);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);
  const [bank, setBank] = useState([]);
  const [pendingModal, setPendingModal] = useState(false);

  const { state, dispatch } = useMerchant();
  const history = useHistory()
  
  const [form] = Form.useForm();

  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;

  const showPendingModal = () => {
    setPendingModal(!pendingModal);
  };

  useEffect(() => {

    form.setFieldsValue({
      nama_usaha:merchantDetail.merchantDetailData.merchantName,
      jenis_usaha:merchantDetail.merchantDetailData.merchantLocationCategory,
      tipe_usaha:merchantDetail.merchantDetailData.merchanLocationSubcategory,
      kepemilikan_tempat_usaha:merchantDetail.merchantDetailData.merchantOwnershipStatus,
      jumlah_karyawan:merchantDetail.merchantDetailData.numberOfEmployee,
      lama_usaha:merchantDetail.merchantDetailData.merchantEstablished,
      daily_revenue:merchantDetail.merchantDetailData.averageDailyRevenue,
      daily_profit:merchantDetail.merchantDetailData.averageDailyProfit,
      top_product:merchantDetail.merchantDetailData.bestSellingProduct ,
      hari_operasional:merchantDetail.merchantDetailData.operationalDays,
      jam_buka:moment(new Date(merchantDetail.merchantDetailData.operationalOpenTime)),
      jam_tutup:moment(new Date(merchantDetail.merchantDetailData.operationalCloseTime)),
      provinsi:merchantDetail.merchantDetailData.province.label,
      kabupaten:merchantDetail.merchantDetailData.city.label,
      kecamatan:merchantDetail.merchantDetailData.district.label,
      kelurahan:merchantDetail.merchantDetailData.suburb.label,
      rt:merchantDetail.merchantDetailData.rt,
      rw:merchantDetail.merchantDetailData.rw,
      bank:merchantDetail.ownerBankId,
      account_bank:merchantDetail.ownerBankAccount,
      bank_account_name:merchantDetail.ownerBankAccountName,
    });
  }, [provinsiData, bankData])

  const locTetap = [
    "Koperasi",
    "Warung",
    "BUMDes",
    "BPR",
    "Komunitas",
    "Pariwisata"
  ]

  const locTidakTetap = [
    "Warung"
  ]

  const handleChange = (info, category) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        switch(category){
          case "fotoSekitar":
            setFotoSekitar(url)
            break;
          case "fotoDepan":
            setFotoDepan(url)
            break;
          case "petugasAkuisisi":
            setPetugasAkuisisi(url)
            break;
          case "pemilik":
            setPemilik(url)
            break;
        }
      });
    }
  };

  const beforeUpload = (file, category) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    getBase64(file, (result) => {
      switch(category){
        case "fotoSekitar":
          setFotoSekitar(result)
          break;
        case "fotoDepan":
          setFotoDepan(result)
          break;
        case "petugasAkuisisi":
          setPetugasAkuisisi(result)
          break;
        case "pemilik":
          setPemilik(result)
          break;
      }
    });

    return false;

    return isJpgOrPng && isLt2M;
  };

  const onProvinceChange = async (value) => {
    const kabupatenData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idProvinsi=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kabupatenData.json();
    setKabupatenList(result?.data);
  };

  const onKabupatenChange = async (value) => {
    const kecamatanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKotaKab=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kecamatanData.json();
    setKecamatanList(result?.data);
  };

  const onKecamatanChange = async (value) => {
    const kelurahanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKecamatan=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kelurahanData.json();
    setKelurahanList(result?.data);
  };

  const onChange = (jenisUsaha) => {
    setjenisUsaha(jenisUsaha);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  

  const onFinish = async(values) => {
    setSpin(true)

    try{
      const {pic_data, primary_data} = state;
      console.log({values})
      console.log({pic_data})
      console.log({primary_data})
      
      values.foto_sekitar = String(fotoSekitar).split(",")[1]
      values.foto_depan = String(fotoDepan).split(",")[1]
      values.foto_petugas = String(petugasAkuisisi).split(",")[1]
      values.foto_pemilik = String(pemilik).split(",")[1]

      let payload = {
        merchantName:values.nama_usaha,
        merchantLocationCategory: values.jenis_usaha,
        merchanLocationSubcategory: values.tipe_usaha,
        merchantOwnershipStatus: values.kepemilikan_tempat_usaha,
        numberOfEmployee: values.jumlah_karyawan,
        merchantEstablished: values.lama_usaha,
        averageDailyRevenue: values.daily_revenue,
        averageDailyProfit: values.daily_profit,
        bestSellingProduct: values.top_product,
        operationalDays: values.hari_operasional,
        operationalOpenTime: values.jam_buka,
        operationalCloseTime: values.jam_tutup,
        province: (merchantDetail.merchantDetailData.province.label == values.provinsi) ? merchantDetail.merchantDetailData.province.code : values.provinsi,
        city: (merchantDetail.merchantDetailData.city.label == values.kabupaten) ? merchantDetail.merchantDetailData.city.code : values.kabupaten,
        district: (merchantDetail.merchantDetailData.district.label == values.kecamatan) ? merchantDetail.merchantDetailData.district.code : values.kecamatan,
        suburb: (merchantDetail.merchantDetailData.suburb.label == values.kelurahan) ? merchantDetail.merchantDetailData.suburb.code : values.kelurahan,
        rt: values.rt,
        rw: values.rw,
        address: values.alamat,
        ownerBankId: values.bank,
        ownerBankAccount: values.account_bank,
        ownerBankAccountName: values.bank_account_name,
        merchantEnviromentImage: values.foto_sekitar,
        merchantFrontImage: values.foto_depan,
        merchantAcquisitionStaffPhoto: values.foto_petugas,
        merchantOwnerPhoto: values.foto_pemilik
      };

      console.log({ payloadReq: payload })

      const response = await axios.patch(process.env.REACT_APP_MAIN_API + "/merchants?updateType=merchantDetail&zonid="+zonid, payload, {
        headers: {
          username: username,
          userid: userId
        }
      })

      if(response?.data?.statusCode === "000") {
        console.log("Sukses Update Tab 3")
        Modal.success({
          title: "Success",
          content: response?.data?.message || 'Update Data Berhasil',
        })
        setDisable(!disable);
        // history.push('/home')
      } else {
        setPendingModal(true)
        setDisable(!disable);
      }
    }catch(err){
      console.log(err)
  
      Modal.error ({
        title: "Error",
        content: err?.message || 'Please Contact Administrator !',
      });
    }finally{
      setSpin(false)
    }
  }

  return (
    <Spin tip="Loading..." spinning={spin}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={45}>
          <Col span={7}>
            <Form.Item label="Nama Usaha" name="nama_usaha">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>

            <Form.Item label="Jenis Usaha" name="jenis_usaha">
              <Select onChange={onChange} disabled={disable}>
                {registerParam['merchantLocationCategory'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tipe Usaha" name="tipe_usaha">
              <Select disabled={disable}>
                {jenisUsaha === 0
                  ? locTetap.map((data) => (
                      <Option value={data} key={data}>
                        {data}
                      </Option>
                    ))
                  : locTidakTetap.map((data) => (
                      <Option value={data} key={data}>
                        {data}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item label="Kepemilikan Tempat Usaha" name="kepemilikan_tempat_usaha">
              <Select disabled={disable}>
                {registerParam['merchantOwnershipType'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Jumlah Karyawan" name="jumlah_karyawan">
              <Select disabled={disable}>
                {registerParam['numberOfEmployee'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Lama Usaha" name="lama_usaha">
              <Select disabled={disable}>
                {registerParam['merchantEstablished'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Rata-Rata Penjualan Harian" name="daily_revenue">
              <Select disabled={disable}>
                {registerParam['averageDailyRevenue'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Rata-Rata Keuntungan Harian" name="daily_profit">
              <Select disabled={disable}>
                {registerParam['averageDailyProfit'].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Produk Terlaris" name="top_product">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Hari Operasional Tempat Usaha" name="hari_operasional">
              <Checkbox.Group disabled={disable}>
                <Space direction="vertical">
                  <Checkbox value="senin">Senin</Checkbox>
                  <Checkbox value="selasa">Selasa</Checkbox>
                  <Checkbox value="rabu">Rabu</Checkbox>
                  <Checkbox value="kamis">Kamis</Checkbox>
                  <Checkbox value="jumat">Jumat</Checkbox>
                  <Checkbox value="sabtu">Sabtu</Checkbox>
                  <Checkbox value="minggu">Minggu</Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            <Row>
              <p>Jam Operasional Tempat Usaha</p>
              <Col span={12}>
                <Form.Item name="jam_buka">
                  <TimePicker onChange={onChange} placeholder="Buka :" disabled={disable} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="jam_tutup">
                  <TimePicker onChange={onChange} placeholder="Tutup :" disabled={disable} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Provisi Tempat Usaha" name="provinsi">
              <Select placeholder="Provinsi" onChange={onProvinceChange} disabled={disable}>
                {provinsiData?.data?.map((item, index) => (
                  <Option key={item?.idProvinsi} value={item?.idProvinsi}>
                    {item.namaProvinsi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Kabupaten Tempat Usaha" name="kabupaten">
              <Select
                placeholder="Kabupaten"
                disabled={kabupatenList.length < 1}
                onChange={onKabupatenChange}
              >
                {kabupatenList?.map((item, index) => (
                  <Option key={item?.idKotaKab} value={item?.idKotaKab}>
                    {item?.namaKotaKab}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Kecamatan Tempat Usaha" name="kecamatan">
              <Select
                placeholder="Kecamatan"
                disabled={kecamatanList.length < 1}
                onChange={onKecamatanChange}
              >
                {kecamatanList?.map((item, index) => (
                  <Option key={item?.idKecamatan} value={item?.idKecamatan}>
                    {item?.namaKecamatan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Kelurahan Tempat Usaha" name="kelurahan">
              <Select placeholder="Kecamatan" disabled={kelurahanList.length < 1}>
                {kelurahanList?.map((item, index) => (
                  <Option key={item?.idKelurahan} value={item?.idKelurahan}>
                    {item?.namaKelurahan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="RT Tempat Usaha" name="rt">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="RW Tempat Usaha" name="rw">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="Alamat Tempat Usaha" name="alamat">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>

            <Form.Item
              label="Nama Bank"
              name="bank"
              rules={[{ required: true, message: 'Bank Name is required' }]}
            >
              <Select placeholder="Nama Bank" disabled={disable}>
                {bankData?.data?.map((item, index) => (
                  <Option key={item?.bankId} value={item?.bankCode}>
                    {item?.bankName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nama Rekening Bank"
              name="bank_account_name"
              rules={[{ required: true, message: 'Nama Rekening Bank is required' }]}
            >
              <Input placeholder="nama rekening bank" disabled={disable} />
            </Form.Item>

            <Form.Item
              label="Nomor Rekening Bank"
              name="account_bank"
              rules={[{ required: true, message: 'Nomor Rekening Bank is required' }]}
            >
              <Input placeholder="Nomor Rekening" disabled={disable} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Foto Sekitar Tempat Usaha" name="foto_sekitar">
              {merchantDetail?.merchantenviromentimage && disable ? (
                <Image src={merchantDetail?.merchantenviromentimage} />
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  disabled={disable}
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(e) => beforeUpload(e, 'fotoSekitar')}
                  onChange={(e) => handleChange(e, 'fotoSekitar')}
                >
                  {fotoSekitar ? (
                    <img
                      src={fotoSekitar}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="Foto Depan Tempat Usaha" name="foto_depan">
              {merchantDetail?.merchantfrontimage && disable ? (
                <Image src={merchantDetail?.merchantfrontimage} />
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  disabled={disable}
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(e) => beforeUpload(e, 'fotoDepan')}
                  onChange={(e) => handleChange(e, 'fotoDepan')}
                >
                  {fotoDepan ? (
                    <img
                      src={fotoDepan}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="Foto Petugas Akuisisi dengan Tempat Usaha" name="foto_petugas">
              {merchantDetail?.merchantacquisitionstaffphoto && disable ? (
                <Image src={merchantDetail?.merchantacquisitionstaffphoto} />
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  disabled={disable}
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(e) => beforeUpload(e, 'petugasAkuisisi')}
                  onChange={(e) => handleChange(e, 'petugasAkuisisi')}
                >
                  {petugasAkuisisi ? (
                    <img
                      src={petugasAkuisisi}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="Foto Pemilik dengan Tempat Usaha" name="foto_pemilik">
              {merchantDetail?.merchantownerphoto && disable ? (
                <Image src={merchantDetail?.merchantownerphoto} />
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  disabled={disable}
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={(e) => beforeUpload(e, 'pemilik')}
                  onChange={(e) => handleChange(e, 'pemilik')}
                >
                  {pemilik ? (
                    <img
                      src={pemilik}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col>
            <a
              onClick={() => {
                setDisable(!disable);
              }}
            >
              Edit
            </a>
          </Col>
        </Row>
        <Row justify="end">
          {disable ? (
            <>
              <Button onClick={onPrevious}>Previous</Button>
              <Button htmlType="button" onClick={onClickedNext} className="ml-3">
                Next
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => { setDisable(!disable);}}>Cancel</Button>
              <Button htmlType="submit" className="ml-3">Save</Button>
            </>
          )}
        </Row>

        {/** Pending Modal */}
        <Modal
          open={pendingModal}
          onOk={showPendingModal}
          onCancel={showPendingModal}
          footer={null}
          style={{ borderRadius: '999rem' }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '1rem',
              justifyContent: 'center',
            }}
          >
            <Form layout="vertical" onFinish={showPendingModal}>
              <Row gutter={45}>
                <img
                  src={warning}
                  style={{ margin: 'auto', marginBottom: '1rem', marginTop: '1rem' }}
                />

                {/* <p align="center">Pending Merchant Approval</p> */}
                <p align="center">
                  <span style={{ marginBottom: '1rem', marginTop: '1rem', fontWeight: 'bold' }}>
                    Pending Merchant Approval
                  </span>
                  <br />
                  <br />
                  This Merchant is still waiting for approval. To edit status, please wait until
                  approval process is done.
                </p>
                <Button
                  variant="danger"
                  htmlType="submit"
                  style={{ marginTop: '1rem', width: '25%', margin: 'auto', borderRadius: '18px' }}
                >
                  Close
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>
        {/* End of Modal Action */}
      </Form>
    </Spin>
  );
};

export default Tab3;
