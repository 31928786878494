import React, {useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {useTable, usePagination} from 'react-table'

const LogsTable = ({
    createdAt,
    lastUpdate,
    status,
    searchData,
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: {pageIndex, pageSize },
    } = useTable(
        {
            columns: columns || [],
            data: data || [],
            initialState: { pageIndex: 0, pageSize: 50 }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
        },
        usePagination
    )

    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
        if(searchData){
            fetchData({ searchData, pageIndex, pageSize })
        }else{
            fetchData({ searchData, pageSize,pageIndex, createdAt, lastUpdate, status })
        }
    }, [fetchData, pageIndex, pageSize, searchData, createdAt, lastUpdate, status])

    return (
        <>
            <div className="table-responsive">
                <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                </span>
                <span>
                    | Go to page: {' '}
                    <input type="number" defaultValue={pageIndex + 1}
                        onChange={e => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }}
                        style={{ width: "50px" }}
                    />
                </span>
                <span>
                    <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                        {
                            [50, 100, 200].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))
                        }
                    </select>
                </span>
                <table {...getTableProps()} className="table table-hover table-striped table-bordered">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                        <tr>
                            {loading ? (
                                // Use our custom loading state to show a loading indicator
                                <td colSpan="10000">Loading...</td>
                            ) : (
                                <td colSpan="10000">
                                    Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
                        results
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page: {' '}
                    <input type="number" defaultValue={pageIndex + 1}
                        onChange={e => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }}
                        style={{ width: "50px" }}
                    />
                </span>
                <span>
                    <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                        {
                            [50, 100, 200].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))
                        }
                    </select>
                </span>
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ marginTop: "5px", marginRight: "5px" }}>{'<<'}</Button>
                    <Button variant="primary" onClick={() => previousPage()} disabled={!canPreviousPage} style={{ marginTop: "5px" }}>{'<'}</Button>
                    <Button variant="primary" onClick={() => nextPage()} style={{ marginLeft: "5px", marginTop: "5px" }} disabled={!canNextPage}>{'>'}</Button>
                    <Button variant="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ marginTop: "5px", marginLeft: "5px" }}>{'>>'}</Button>
                </div>
            </div>
        </>
    )
}

export default LogsTable