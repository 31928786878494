import React, {useEffect, useState} from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'

const PageTitle = (props) => {
    let history = useHistory()

    const [canBack, setcanBack] = useState(false)
    useEffect(() => {
        setcanBack(props.canBack)
        return () => {
            setcanBack(false)
        }
    }, [props.canBack])
    return (
        <>
            <Row>
            <Col><h2 style={{fontWeight: "bolder"}}>{props.title}</h2></Col>
            <Col md={2}>
            {canBack ? (<Button variant="danger" onClick={() => history.goBack()}>Back</Button>) : ("")}
            </Col>
            </Row>
            <hr></hr>         
        </>
    )
}

export default PageTitle