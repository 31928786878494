import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    const style = {
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        // textAlign: "center",
        padding: "20px",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
    }


    return (
        <div>
            <div style={style}>
                <Row>
                    <Col md={10}>
                    Copyright <FontAwesomeIcon icon={faCopyright} />{' '}{new Date().getFullYear()}  PT. Harsya Remitindo
                    </Col>
                    <Col md={2} style={{textAlign: "right"}}>
                        {`Version ${process.env.REACT_APP_VERSION}`}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer