import * as React from "react";

export const MerchantContext = React.createContext({});

function merchantReducer(state, action) {
  switch (action.type) {
    case "primary_data": {
      return {
        ...state,
        primary_data: {
          ...state.primary_data,
          ...action.payload,
        },
      };
    }
    case "pic_data": {
      return {
        ...state,
        pic_data: {
          ...state.pic_data,
          ...action.payload,
        },
      };
    }
    case "dataTab3": {
      return {
        ...state,
        dataTab3: {
          ...state.dataTab3,
          ...action.payload,
        },
      };
    }
    case "merchant_data": {
      return {
        ...state,
        merchant_data: {
          ...state.merchant_data,
          ...action.payload,
        },
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function MerchantProvider({ children }) {
  const [state, dispatch] = React.useReducer(merchantReducer, {
    primary_data: {},
    pic_data: {},
    dataTab3: {},
    merchant_data: {},
  });

  const value = { state, dispatch };
  return (
    <MerchantContext.Provider value={value}>
      {children}
    </MerchantContext.Provider>
  );
}
