import {
    Button,
    Form,
    Input,
    Checkbox,
    Col,
    Row,
    Space,
    Select,
    message,
    Upload,
    TimePicker,
    Image,
    Modal,
    Spin,
    View
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useRequest } from '../../hooks/useRequest';
import { useMerchant } from '../../hooks/useMerchant';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useFilePicker } from 'use-file-picker';
import axios from 'axios';
import warning from '../../assets/warning.png';
import { useCookies } from 'react-cookie';

const { Option, OptGroup } = Select;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const Tab4 = ({ registerParam, merchantDetail, zonid, onPrevious }) => {
    let history = useHistory();
    const [selectAkta, { aktaFile, }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });
    const [selectKumHam, { kumHamFile }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });
    const [selectSiup, { siupFile, }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });
    const [selectSkdp, { skdpFile }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });
    const [selectTdp, { tdpFile, }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });
    const [selectSppkp, { sppkpFile }] = useFilePicker({
        accept: '.pdf',
        multiple: false,
    });

    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const [disable, setDisable] = useState(true);
    const [pendingModal, setPendingModal] = useState(false);

    const { state, dispatch } = useMerchant();

    const showPendingModal = () => {
        setPendingModal(!pendingModal);
    };

    const onFinish = async (values) => {
        dispatch({ type: 'primary_data', payload: values });
        console.log({ values });

        let payload = {
            merchantName: values.nama_usaha,
            ownerName: values.nama_pemilik,
            businessType: values.businessType,
            ownerNpwp: values.npwp_pemilik,
            ownerPhone: '0' + values.nomor_hp,
            ownerEmail: values.email
        }

        const response = await axios.patch(process.env.REACT_APP_MAIN_API + '/merchants?updateType=primary&zonid=' + zonid, payload, {
            headers: {
                username: process.env.API_USERNAME,
                userid: process.env.API_USERID
            }
        })

        console.log('Response Update Primary :', response)
        if (response?.data?.statusCode === '000') {
            console.log('Sukses Update Tab 1')
            showPendingModal();
            setDisable(!disable);
            // window.location.reload()
        }
    };

    const updatePrimary = async () => {
        let payload = {
            merchantName: form.getFieldValue('nama_usaha'),
            ownerName: form.getFieldValue('nama_pemilik'),
            ownerPhone: '0' + form.getFieldValue('nomor_hp'),
            ownerEmail: form.getFieldValue('email')
        }

        const response = await axios.patch(process.env.REACT_APP_MAIN_API + '/merchants?updateType=primary&zonid=' + zonid, payload, {
            headers: {
                username: process.env.API_USERNAME,
                'user_id': process.env.API_USERID
            }
        })

        console.log('Response Update Primary :', response)
        if (response?.data?.statusCode === '000') {
            console.log('Sukses Update Tab 4')
            window.location.reload()
        }
    }

    form.setFieldsValue({
        nama_usaha: merchantDetail?.merchantName,
        nama_pemilik: merchantDetail.merchantOwnerName,
        businessType: merchantDetail.merchantBusinessType,
        npwp_pemilik: merchantDetail.merchantNpwp,
        nomor_hp: merchantDetail.merchantOwnerPhone,
        email: merchantDetail.merchantOwnerEmail
    });

    return (
        <div>
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={80}>
                    <Col span={10}>
                        <Form.Item label="Akta Pendirian atau Anggaran Dasar" name="akta_pendirian">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectAkta()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Pengesahan Menteri Hukum dan HAM" name="kumham">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectKumHam()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>

                        <Form.Item label="Surat Ijin Usaha Perdagangan" name="siup">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectSiup()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>

                        <Form.Item label="Surat Keterangan Domisili Usaha" name="skdu">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectSkdp()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>

                        <Form.Item label="Tanda Daftar Perusahaan" name="tdp">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectTdp()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>

                        <Form.Item label="Surat Pengukuhan Pengusaha Kena Pajak" name="sppkp">
                            <Row style={{ width: '170px', justifyContent: 'space-between' }}>
                                <Button disabled={disable} onClick={() => selectSppkp()}>Update file </Button>
                                <Button
                                    onClick={() => {
                                        setDisable(!disable);
                                    }}
                                >
                                    View
                                </Button>
                            </Row>
                        </Form.Item>
                    </Col>

                    <Col >
                        <a onClick={() => { setDisable(!disable) }}>Edit</a>
                    </Col>
                </Row>

                <Row justify="end">
                    <Button onClick={onPrevious}>Previous</Button>
                    <Button htmlType="submit" className="ml-3">
                        Save
                    </Button>
                </Row>

                {/** Pending Modal */}
                <Modal
                    open={pendingModal}
                    onOk={showPendingModal}
                    footer={null}
                    style={{ borderRadius: '999rem' }}
                >
                    <div style={{
                        display: 'flex',
                        marginBottom: '1rem',
                        justifyContent: 'center',
                    }}>
                        <Form layout='vertical' onFinish={showPendingModal}>
                            <Row gutter={45}>
                                <img src={warning} style={{ margin: 'auto', marginBottom: '1rem', marginTop: '1rem' }} />

                                {/* <p align='center'>Pending Merchant Approval</p> */}
                                <p align='center'><span style={{ marginBottom: '1rem', marginTop: '1rem', fontWeight: 'bold' }}>Pending Merchant Approval</span><br /><br /><span syle={{ marginLeft: '1rem', marginRight: '1rem' }}>This Merchant is still waiting for approval. To edit status, please wait until approval process is done.</span></p>
                                <Button variant='danger' htmlType='submit' style={{ marginTop: '1rem', width: '25%', margin: 'auto', borderRadius: '18px' }}>Close</Button>

                            </Row>
                        </Form>
                    </div>
                </Modal>
                {/* End of Modal Action */}
            </Form>
        </div>
    );
};

export default Tab4;