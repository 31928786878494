import React, {useEffect,useState} from 'react'
import {
    Tabs, 
    Tab, 
    Table, 
    Card, 
    CardGroup,
    Button,
    Badge,
    Spinner
} from 'react-bootstrap'
import DefaultUserIcon from '../../assets/logo/default-user.png'

const ProfileTabs = (props) => {
    const [key, setkey] = useState('detailIdentitas')
    const [userData, setuserData] = useState({})
    const [ktpData, setktpData] = useState({})
    const [usahaData, setusahaData] = useState({})

    useEffect(() => {
        setuserData(props.userData)
        setktpData(props.userData?.identitas?.ktp)
        setusahaData(props.userData?.keterangan_tempat_usaha)

        return () => {
            setuserData({})
            setktpData({})
            setusahaData({})
        }
    }, [props.userData])


    return (
        <Tabs activeKey={key} id="profile-tabs" onSelect={(k) => setkey(k)}>
        <Tab eventKey="detailIdentitas" title="Detail Identitas">
        <Table striped bordered hover size="sm">
            <tbody>
                <tr>
                    <td width="100px"><b>No. KTP</b></td>
                    <td>{ktpData?.nomor_ktp ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Nama Lengkap</b></td>
                    <td>{ktpData?.nama ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Jenis Kelamin</b></td>
                    <td>{ktpData?.gender ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Pendidikan</b></td>
                    <td>{ktpData?.pendidikan ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Tempat Lahir</b></td>
                    <td>{ktpData?.tempat_lahir ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Tanggal Lahir</b></td>
                    <td>{ktpData?.tanggal_lahir ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Agama</b></td>
                    <td>{ktpData?.agama ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Status Pernikahan</b></td>
                    <td>{ktpData?.status_pernikahan ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Alamat</b></td>
                    <td>{ktpData?.alamat ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kelurahan</b></td>
                    <td>{ktpData?.kelurahan ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kecamatan</b></td>
                    <td>{ktpData?.kecamatan ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kota</b></td>
                    <td>{ktpData?.kota ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Provinsi</b></td>
                    <td>{ktpData?.provinsi ?? "NO DATA"}</td>
                </tr>
            </tbody>
        </Table>
        <CardGroup>
            <Card>
                <Card.Img variant="top" src={ktpData?.photo_ktp ?? DefaultUserIcon} />
                <Card.Body>
                <Card.Title>Foto KTP</Card.Title>
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        </CardGroup>
        </Tab>
        <Tab eventKey="detailUsaha" title="Detail Usaha">
        <h5>Keterangan Usaha </h5>
        <Table striped bordered hover size="sm">
            <tbody>
                <tr>
                    <td width="100px"><b>Jenis Mitra</b></td>
                    <td>{userData?.jenis_mitra ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Nama Mitra</b></td>
                    <td>{userData?.nama_mitra ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kepemilikan Lokasi Usaha</b></td>
                    <td>{usahaData?.kepemilikan_lokasi ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Tipe Loket</b></td>
                    <td>{usahaData?.tipe_loket ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Produk Paling Laris</b></td>
                    <td>{usahaData?.produk_paling_laris ?? "NO DATA"}</td>
                </tr>
            </tbody>
        </Table>
        <h5>Lokasi Usaha </h5>
        <Table striped bordered hover size="sm">
            <tbody>
                <tr>
                    <td width="100px"><b>Alamat</b></td>
                    <td>{userData?.alamat_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kota</b></td>
                    <td>{userData?.kota_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kelurahan</b></td>
                    <td>{userData?.kelurahan_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Kecamatan</b></td>
                    <td>{userData?.kecamatan_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Provinsi</b></td>
                    <td>{userData?.provinsi_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Jam Operasional</b></td>
                    <td>{`${usahaData?.jam_buka} - ${usahaData?.jam_tutup}` ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Jumlah Karyawan</b></td>
                    <td>{usahaData?.jumlah_karyawan ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Hari Kerja</b></td>
                    <td>{usahaData?.hari_kerja ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Lama Usaha</b></td>
                    <td>{usahaData?.lama_usaha ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Rata - rata Penjualan Harian</b></td>
                    <td>{usahaData?.total_penjualan_harian ?? "NO DATA"}</td>
                </tr>
                <tr>
                    <td width="100px"><b>Rata - rata Keuntungan Harian</b></td>
                    <td>{usahaData?.total_keuntungan_harian ?? "NO DATA"}</td>
                </tr>
            </tbody>
        </Table>
        <CardGroup>
        <Card>
            <Card.Img variant="top" src={userData?.foto_usaha?.[0] ?? DefaultUserIcon} />
            <Card.Body>
            <Card.Title>Foto tampak bangunan disebelah kanan dan kiri serta jalan depan toko</Card.Title>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
        <Card>
            <Card.Img variant="top" src={userData?.foto_usaha?.[1] ?? DefaultUserIcon} />
            <Card.Body>
            <Card.Title>Foto bagian depan toko dan nama toko</Card.Title>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
        <Card>
            <Card.Img variant="top" src={userData?.foto_usaha?.[2] ?? DefaultUserIcon} />
            <Card.Body>
            <Card.Title>Foto Area Leader di depan lokasi tampak barang dagangan</Card.Title>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
        <Card>
            <Card.Img variant="top" src={userData?.foto_usaha?.[3] ?? DefaultUserIcon} />
            <Card.Body>
            <Card.Title>Foto pemilik toko didepan toko</Card.Title>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
        </CardGroup>
        </Tab>
        <Tab eventKey="fiturLain" title="Pengaturan Fitur">
            <Table striped bordered hover size="sm">
                <thead>
                <th>Nama Fitur</th>
                <th>Status</th>
                <th>Tanggal Aktivasi</th>
                <th>Action</th>
                </thead>
                <tbody>
                <tr>
                    <td width="100px">Kirim Uang</td>
                    <td width="100px">
                        State: {props.isLoadingBtn.kirimUang.toString()}
                        {/* Status New */}
                        {userData?.status_approve_kirim_uang === null && <Badge variant="danger">New/Non Aktif</Badge> }
                        {/* Status Aktif */}
                        {userData?.status_approve_kirim_uang === 1 && <Badge variant="success">Aktif</Badge> }
                        {/* Status Non Aktif */}
                        {userData?.status_approve_kirim_uang === 2 && <Badge variant="danger">Non Aktif</Badge> }
                        {/* Status Menunggu Approval */}
                        {userData?.status_approve_kirim_uang === 3 && <Badge variant="warning">Menunggu Approval</Badge> }
                    </td>
                    <td>{userData?.tgl_approve_kirim_uang}</td>
                    <td>
                        {props.isLoadingBtn.kirimUang ? (
                            <>
                            {/* Status New */}
                            {userData?.status_approve_kirim_uang === null &&
                            <Button variant="outline-primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </Button>}
                            {/* Status Non Aktif */}
                            {userData?.status_approve_kirim_uang === 2 &&
                            <Button variant="outline-primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </Button>}
                            </>
                        ) : (
                            userData?.status_approve_kirim_uang === null && <Button variant="outline-primary" size="sm"  onClick={() => props.ActivateFeatures("KirimUang")}>Aktifkan</Button>
                        )}
                        {/* Status New */}
                        {/* {userData?.status_approve_kirim_uang === null && <Button variant="outline-primary" size="sm" disabled={props.isLoadingBtn.kirimUang} onClick={() => props.ActivateFeatures("KirimUang")}>Aktifkan</Button>} */}
                        {/* Status Aktif */}
                        {/* {userData?.status_approve_kirim_uang === 1 && <Button variant="outline-danger" size="sm" disabled={props.isLoadingBtn.kirimUang} onClick={() => props.DeactivateFeatures("KirimUang")}>Non Aktif</Button> } */}
                        {/* Status Non Aktif */}
                        {/* {userData?.status_approve_kirim_uang === 2 && <Button variant="outline-primary" size="sm" disabled={props.isLoadingBtn.kirimUang} onClick={() => props.ActivateFeatures("KirimUang")}>Aktifkan</Button> } */}
                        {/* Status Menunggu Approval */}
                        {userData?.status_approve_kirim_uang === 3 && <Button variant="warning" size="sm" disabled>Menunggu Approval</Button> }
                    </td>
                </tr>
                </tbody>
            </Table>
        </Tab>
        </Tabs>
    )
}

export default ProfileTabs