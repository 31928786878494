import {
  Button,
  Form,
  Input,
  Checkbox,
  Col,
  Row,
  Space,
  Select,
  message,
  Upload,
  TimePicker,
  Modal,
  Spin
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { useMerchant } from "../../hooks/useMerchant";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

const { Option, OptGroup } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Tab3 = ({ onClickedNext, registerParam, onPrevious }) => {
  const [jenisUsaha, setjenisUsaha] = useState();
  const [loading, setLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [fotoSekitar, setFotoSekitar] = useState();
  const [fotoDepan, setFotoDepan] = useState();
  const [petugasAkuisisi, setPetugasAkuisisi] = useState();
  const [pemilik, setPemilik] = useState();
  const [kabupatenList, setKabupatenList] = useState([]);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [cookies] = useCookies(['udata']);
  const { userId, username } = cookies.udata;

  const { state, dispatch } = useMerchant();
  const history = useHistory()
  const [form] = Form.useForm();

  const locTetap = [
    "Koperasi",
    "Warung",
    "BUMDes",
    "BPR",
    "Komunitas",
    "Pariwisata"
  ]

  const locTidakTetap = [
    "Warung"
  ]

  const handleChange = (info, category) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        switch (category) {
          case "fotoSekitar":
            setFotoSekitar(url)
            break;
          case "fotoDepan":
            setFotoDepan(url)
            break;
          case "petugasAkuisisi":
            setPetugasAkuisisi(url)
            break;
          case "pemilik":
            setPemilik(url)
            break;
        }
      });
    }
  };

  const beforeUpload = (file, category) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    getBase64(file, (result) => {
      switch (category) {
        case "fotoSekitar":
          setFotoSekitar(result)
          break;
        case "fotoDepan":
          setFotoDepan(result)
          break;
        case "petugasAkuisisi":
          setPetugasAkuisisi(result)
          break;
        case "pemilik":
          setPemilik(result)
          break;
      }
    });

    return false;

    return isJpgOrPng && isLt2M;
  };

  const { data: provinsiData } = useRequest(
    "/config/locationDetail?idProvinsi=ALL"
  );

  const onProvinceChange = async (value) => {
    const kabupatenData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idProvinsi=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kabupatenData.json();
    setKabupatenList(result?.data);
  };

  //Suburb & Districtnya ketuker, ikutin abang yg buat API aja
  const onKabupatenChange = async (value) => {
    const kecamatanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKotaKab=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kecamatanData.json();
    console.log("kecamatan:", result.data)
    setKecamatanList(result?.data);
  };

  const onKecamatanChange = async (value) => {
    const kelurahanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKecamatan=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kelurahanData.json();
    console.log("kelurahan:", result.data)
    setKelurahanList(result?.data);
  };

  const onKelurahanChange = async (value) => {
    const bankList = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/bank`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await bankList.json();
    console.log("bank list:", result.data)
    setBankList(result?.data);
  };

  const onChange = (jenisUsaha) => {
    setjenisUsaha(jenisUsaha);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = async (values) => {
    console.log(values)
    values.foto_sekitar = String(fotoSekitar).split(",")[1]
    values.foto_depan = String(fotoDepan).split(",")[1]
    values.foto_petugas = String(petugasAkuisisi).split(",")[1]
    values.foto_pemilik = String(pemilik).split(",")[1]

    dispatch({ type: "dataTab3", payload: values });
    onClickedNext();
  }

  return (
    <Spin tip="Loading..." spinning={spin}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={45}>
          <Col span={8}>
            <Form.Item
              label="Nama Usaha"
              name="nama_usaha"
              rules={[{ required: true, message: "Nama Usaha is required" }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>

            <Form.Item
              label="Lokasi Usaha"
              name="jenis_usaha"
              rules={[{ required: true, message: "Jenis Usaha is required" }]}
            >
              <Select onChange={onChange}>
                {registerParam["merchantLocationCategory"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tipe Usaha"
              name="tipe_usaha"
              rules={[{ required: true, message: "Tipe Usaha is required" }]}>
              <Select>
                {jenisUsaha === 0
                  ? locTetap.map((data) => (
                    <Option value={data} key={data}>
                      {data}
                    </Option>
                  ))
                  : locTidakTetap.map((data) => (
                    <Option value={data} key={data}>
                      {data}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Kepemilikan Tempat Usaha"
              name="kepemilikan_tempat_usaha"
              rules={[{ required: true, message: "Kepemilikan Tempat Usaha is required" }]}>
              <Select>
                {registerParam["merchantOwnershipType"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Jumlah Karyawan"
              name="jumlah_karyawan"
              rules={[{ required: true, message: "Jumlah Karyawan is required" }]}>
              <Select>
                {registerParam["numberOfEmployee"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Lama Usaha"
              name="lama_usaha"
              rules={[{ required: true, message: "Lama Usaha is required" }]}>
              <Select>
                {registerParam["merchantEstablished"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Rata-Rata Penjualan Harian"
              name="daily_revenue"
              rules={[{ required: true, message: "Penjualan Harian is required" }]}>
              <Select>
                {registerParam["averageDailyRevenue"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Rata-Rata Keuntungan Harian"
              name="daily_profit"
              rules={[{ required: true, message: "Keuntungan Harian is required" }]}>
              <Select>
                {registerParam["averageDailyProfit"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Produk Terlaris"
              name="top_product"
              rules={[{ required: true, message: "Produk Terlaris is required" }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Hari Operasional Tempat Usaha"
              name="hari_operasional"
              rules={[{ required: true, message: "Hari Operasional is required" }]}>
              <Checkbox.Group>
                <Space direction="vertical">
                  <Checkbox value="senin">Senin</Checkbox>
                  <Checkbox value="selasa">Selasa</Checkbox>
                  <Checkbox value="rabu">Rabu</Checkbox>
                  <Checkbox value="kamis">Kamis</Checkbox>
                  <Checkbox value="jumat">Jumat</Checkbox>
                  <Checkbox value="sabtu">Sabtu</Checkbox>
                  <Checkbox value="minggu">Minggu</Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            <Row>
              <p>Jam Operasional Tempat Usaha</p>
              <Col span={12}>
                <Form.Item
                  name="jam_buka"
                  rules={[{ required: true, message: "Jam Buka is required" }]}>
                  <TimePicker onChange={onChange} placeholder="Buka :" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="jam_tutup"
                  rules={[{ required: true, message: "Jam Tutup is required" }]}>
                  <TimePicker onChange={onChange} placeholder="Tutup :" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Provinsi Tempat Usaha"
              name="provinsi"
              rules={[{ required: true, message: "Provinsi is required" }]}>
              <Select placeholder="Provinsi" onChange={onProvinceChange}>
                {provinsiData?.data?.map((item, index) => (
                  <Option key={item?.idProvinsi} value={item?.idProvinsi}>
                    {item.namaProvinsi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kabupaten Tempat Usaha"
              name="kabupaten"
              rules={[{ required: true, message: "Kabupaten is required" }]}>
              <Select
                placeholder="Kabupaten"
                disabled={kabupatenList.length < 1}
                onChange={onKabupatenChange}
              >
                {kabupatenList?.map((item, index) => (
                  <Option key={item?.idKotaKab} value={item?.idKotaKab}>
                    {item?.namaKotaKab}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kecamatan Tempat Usaha"
              name="kecamatan"
              rules={[{ required: true, message: "Kecamatan is required" }]}>
              <Select
                placeholder="Kecamatan"
                disabled={kecamatanList.length < 1}
                onChange={onKecamatanChange}
              >
                {kecamatanList?.map((item, index) => (
                  <Option key={item?.idKecamatan} value={item?.idKecamatan}>
                    {item?.namaKecamatan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kelurahan Tempat Usaha"
              name="kelurahan"
              rules={[{ required: true, message: "Kelurahan is required" }]}>
              <Select
                placeholder="Kecamatan" disabled={kelurahanList.length < 1} onChange={onKelurahanChange} >
                {kelurahanList?.map((item, index) => (
                  <Option key={item?.idKelurahan} value={item?.idKelurahan}>
                    {item?.namaKelurahan}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="RT Tempat Usaha"
              name="rt"
              rules={[{ required: true, message: "RT is required" }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>
            <Form.Item
              label="RW Tempat Usaha"
              name="rw"
              rules={[{ required: true, message: "RW is required" }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>
            <Form.Item
              label="Alamat Tempat Usaha"
              name="alamat"
              rules={[{ required: true, message: "Alamat is required" }]}>
              <Input placeholder="input placeholder" />
            </Form.Item>
            <Form.Item
              label="Nama Bank"
              name="bank"
              rules={[{ required: true, message: "Bank Name is required" }]}>
              <Select placeholder="Nama Bank" disabled={bankList.length < 1}>
                {bankList?.map((item, index) => (
                  <Option key={item?.bankId} value={item?.bankCode}>
                    {item?.bankName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nama Rekening Bank"
              name="bankAccountName"
              rules={[{ required: true, message: "Nama Rekening Bank is required" }]}>
              <Input placeholder="nama rekening bank" />
            </Form.Item>

            <Form.Item
              label="Nomor Rekening Bank"
              name="accountBank"
              rules={[{ required: true, message: "Nomor Rekening Bank is required" }]}>
              <Input placeholder="Nomor Rekening" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Foto Sekitar Tempat Usaha"
              name="foto_sekitar"
              rules={[{ required: true, message: "Foto Sekitar is required" }]}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={(e) => beforeUpload(e, "fotoSekitar")}
                onChange={(e) => handleChange(e, "fotoSekitar")}
              >
                {fotoSekitar ? (
                  <img
                    src={fotoSekitar}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="Foto Depan Tempat Usaha"
              name="foto_depan"
              rules={[{ required: true, message: "Foto Depan is required" }]}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={(e) => beforeUpload(e, "fotoDepan")}
                onChange={(e) => handleChange(e, "fotoDepan")}
              >
                {fotoDepan ? (
                  <img
                    src={fotoDepan}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="Foto Petugas Akuisisi dengan Tempat Usaha"
              name="foto_petugas"
              rules={[{ required: true, message: "Foto Petugas is required" }]}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={(e) => beforeUpload(e, "petugasAkuisisi")}
                onChange={(e) => handleChange(e, "petugasAkuisisi")}
              >
                {petugasAkuisisi ? (
                  <img
                    src={petugasAkuisisi}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="Foto Pemilik dengan Tempat Usaha"
              name="foto_pemilik"
              rules={[{ required: true, message: "Foto Pemilik is required" }]}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={(e) => beforeUpload(e, "pemilik")}
                onChange={(e) => handleChange(e, "pemilik")}
              >
                {pemilik ? (
                  <img
                    src={pemilik}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button onClick={onPrevious}>Previous</Button>
          <Button htmlType="submit" className="ml-3">
            Next
          </Button>
        </Row>
      </Form>
    </Spin>
  );
};

export default Tab3;
