import {
  Button,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  message,
  Upload,
  DatePicker,
  Space,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useMerchant } from "../../hooks/useMerchant";
import { useRequest } from "../../hooks/useRequest";
import Services from "../../services";
import { useCookies } from 'react-cookie';

const { Option, OptGroup } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Tab2 = ({ onClickedNext, registerParam, onPrevious }) => {

  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const [fotoKtp, setFotoKtp] = useState();
  const [fotoSelfie, setFotoSelfie] = useState();
  const { state, dispatch } = useMerchant();

  const [kabupatenList, setKabupatenList] = useState([]);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);
  const [cookies] = useCookies(['udata']);
  const { userId, username } = cookies.udata;

  const [form] = Form.useForm();

  const { primary_data } = state;

  console.log({ registerParam })

  //AutoFill PIC
  // useEffect(() => {
  //   if (primary_data?.nama_pemilik) {
  //     form.setFieldsValue({
  //       nama_pic: primary_data.nama_pemilik,
  //     });
  //   }
  // }, [primary_data]);

  const { data: provinsiData } = useRequest(
    "/config/locationDetail?idProvinsi=ALL"
  );

  const onProvinceChange = async (value) => {
    const kabupatenData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idProvinsi=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kabupatenData.json();
    setKabupatenList(result?.data);
  };

  const onKabupatenChange = async (value) => {
    const kecamatanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKotaKab=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kecamatanData.json();
    setKecamatanList(result?.data);
  };

  const onKecamatanChange = async (value) => {
    const kelurahanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKecamatan=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kelurahanData.json();
    setKelurahanList(result?.data);
  };

  const handleChange = (info, category) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        switch (category) {
          case "ktp":
            setFotoKtp(url)
            break;
          case "selfie":
            setFotoSelfie(url)
            break;
        }
        // setImageUrl(url);
      });
    }
  };

  const beforeUpload = (file, category) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    getBase64(file, (result) => {
      switch (category) {
        case "ktp":
          setFotoKtp(result)
          break;
        case "selfie":
          setFotoSelfie(result)
          break;
      }
      // setImageUrl(url);
    });

    return false;

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = async (values) => {
    console.log({ values });

    values.foto_ktp = String(fotoKtp)?.split(",")[1]
    values.foto_selfie = String(fotoSelfie)?.split(',')[1]

    dispatch({ type: "pic_data", payload: values });

    onClickedNext();
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row gutter={45}>
        <Col span={8}>
          <Form.Item
            label="Nama PIC"
            name="nama_pic"
            rules={[{ required: true, message: "Nama PIC is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Nomor HP"
            name="nomor_hp"
            rules={[{ required: true, message: "Nomor HP is required" }]}>
            <Input addonBefore="+62" placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Jabatan PIC"
            name="status_kepemilikan"
            rules={[{ required: true, message: "Status Kepemilikan is required" }]}>
            <Select>
              {registerParam["merchantPicOwnershipStatus"].map((data) => (
                <Option value={data.value} key={data.value}>
                  {data.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Nomor KTP"
            name="nomor_ktp"
            rules={[{ required: true, message: "Nomor KTP is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Tempat Lahir"
            name="tempat_lahir"
            rules={[{ required: true, message: "Tempat Lahir is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Tanggal Lahir"
            name="tgl_lahir"
            rules={[{ required: true, message: "Tanggal Lahir is required" }]}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Provinsi Tempat Tinggal"
            name="provinsi"
            rules={[{ required: true, message: "Provinsi is required" }]}>
            <Select placeholder="Provinsi" onChange={onProvinceChange}>
              {provinsiData?.data?.map((item, index) => (
                <Option key={item?.idProvinsi} value={item.idProvinsi}>
                  {item.namaProvinsi}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kabupaten Tempat Tinggal"
            name="kabupaten"
            rules={[{ required: true, message: "Kabupaten is required" }]}>
            <Select
              placeholder="Kabupaten"
              disabled={kabupatenList.length < 1}
              onChange={onKabupatenChange}>
              {kabupatenList?.map((item, index) => (
                <Option key={item?.idKotaKab} value={item?.idKotaKab}>
                  {item?.namaKotaKab}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kecamatan Tempat Tinggal"
            name="kecamatan"
            rules={[{ required: true, message: "Kecamatan is required" }]}
          >
            <Select
              placeholder="Kecamatan"
              disabled={kecamatanList.length < 1}
              onChange={onKecamatanChange}
            >
              {kecamatanList?.map((item, index) => (
                <Option key={item?.idKecamatan} value={item?.idKecamatan}>
                  {item?.namaKecamatan}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kelurahan Tempat Tinggal"
            name="kelurahan"
            rules={[{ required: true, message: "Kelurahan is required" }]}
          >
            <Select placeholder="Kelurahan" disabled={kelurahanList.length < 1}>
              {kelurahanList?.map((item, index) => (
                <Option key={item?.idKelurahan} value={item?.idKelurahan}>
                  {item?.namaKelurahan}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="RT Tempat Tinggal"
            name="rt"
            rules={[{ required: true, message: "RT is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="RW Tempat Tinggal"
            name="rw"
            rules={[{ required: true, message: "RW is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Alamat Tempat Tinggal"
            name="alamat"
            rules={[{ required: true, message: "Alamat is required" }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Agama"
            name="agama"
            rules={[{ required: true, message: "Agama is required" }]}>
            <Select>
              {registerParam["religion"].map((data) => (
                <Option value={data.value} key={data.value}>
                  {data.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Pendidikan Terakhir"
            name="pendidikan_terakhir"
            rules={[{ required: true, message: "Pendidikan Terakhir is required" }]}>
            <Select>
              {registerParam["educationLevel"].map((data) => (
                <Option value={data.value} key={data.value}>
                  {data.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status Perkawinan"
            name="status_perkawinan"
            rules={[{ required: true, message: "Status Perkawinan is required" }]}>
            <Select>
              {registerParam["maritalStatus"].map((data) => (
                <Option value={data.value} key={data.value}>
                  {data.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Foto KTP"
            name="foto_ktp"
            rules={[{ required: true, message: "Foto KTP is required" }]}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(e) => beforeUpload(e, "ktp")}
              onChange={(e) => handleChange(e, "ktp")}
            // beforeUpload={beforeUpload}
            // onChange={handleChange}
            >
              {fotoKtp ? (
                <img
                  src={fotoKtp}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Foto Selfie dengan KTP"
            name="foto_selfie"
            rules={[{ required: false, message: "Foto Selfi is required" }]}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(e) => beforeUpload(e, "selfie")}
              onChange={(e) => handleChange(e, "selfie")}
            // beforeUpload={beforeUpload}
            // onChange={handleChange}
            >
              {fotoSelfie ? (
                <img
                  src={fotoSelfie}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end">
        <Button onClick={onPrevious}>Previous</Button>
        <Button htmlType="submit" className="ml-3">
          Next
        </Button>
      </Row>
    </Form>
  );
};

export default Tab2;
