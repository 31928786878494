import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Stack,
  Row,
  Col,
  Container,
  Card,
  // Modal,
  Alert,
  Spinner,
  Button,
  
} from "react-bootstrap";
import {
  Topbar,
  PageTitle,
  Footer,
  LogsTable,
  Filters,
  Searchbar,
} from "../../components";
import {
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Form,
  Select,
  Spin,
  message as popupMessage,
  Button as AntdButton,
} from "antd";

import useSWR from "swr";
import { getColorBtn } from "../../utilities";
import Services from "../../services";
import { Router, useHistory } from "react-router-dom";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useRequest } from "../../hooks/useRequest";
import warning from "../../assets/warning.png";
import logo from "../../assets/logo/logo.png";
import { useCookies } from 'react-cookie';

const { Search } = Input;
const { Option, OptGroup } = Select;

let page = 1;

const MemberList = () => {
  // Browser history instance
  let history = useHistory();
  // Get userId from cookie
  const [form] = Form.useForm();
  const [userStatus, setUserStatus] = useState({});
  const [pendingModal, setPendingModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [merchantDeactivateZonid, setMerchantDeactivateZonid] = useState();

  let selectedZonid = '1111111111111';

  const [page, setPage] = useState(1);
  const [spin, setSpin] = useState(false);


  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;

  const [filterParams, setFilterParams] = useState({
    approvalStatus: "",
    accountStatus: "",
    createdAt: "",
    updatedAt: "",
  });

  const showPendingModal = () => {
    setPendingModal(!pendingModal);
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const checkFilterDate = () => {
    if(filterParams?.createdAt && filterParams?.createFrom){
      console.log("createAt: "+filterParams.createdAt.format('YYYY-MM-DD'))
      console.log("createFrom: "+filterParams.createFrom.format('YYYY-MM-DD'))
      return `&createdAtFrom=${filterParams.createdAt.format('YYYY-MM-DD')}&createdAtTo=${filterParams.createFrom.format('YYYY-MM-DD')}`;
    }

    return "";
  };

  const deactivateMerchant = async(zonid) => {
    setSpin(true)

    try{
  
      // const zonid = merchantDeactivateZonid;
      const deactivate = await axios.delete(process.env.REACT_APP_MAIN_API + `/merchants/deactivate?zonid=${zonid}`,{
        headers: {
          username: username,
          userid: userId
        }
      })
      
      if(deactivate?.data?.statusCode === "000") {
        Modal.success({
          title: "Success",
          content: deactivate?.data?.message || 'Deactivate Merchant Berhasil',
        })
        history.push('/home')
      }else{
        Modal.error ({
          title: "Error",
          content: deactivate?.data?.message || 'Deactivate Merchant Error !',
        });
      }
    }catch(err){
      console.error(err);

      Modal.error ({
        title: "Error",
        content: err?.message || 'Please Contact Administrator !',
      });

    }finally{
      setSpin(false)
    }
  }
  let { data: testData, isLoading } = useRequest('/merchants?orderBy=desc'+checkFilterDate());

  const remapData = testData?.data?.map((item) => ({
    ...item,
    key: item?.zonid,
  }));

  useEffect(() => {
    setDataSource(remapData);
  }, [testData]);

  const onStatusChange = async (values) => {
    console.log("ON Status Change : ", { values });
    console.log("ON Status Zonid : ", userStatus.zonid);

    let payload = {
      status: form.getFieldValue("active_status"),
    };

    const response = await axios.patch(
      process.env.REACT_APP_MAIN_API +
        "/merchants?updateType=status&zonid=" +
        userStatus.zonid,
      payload,
      {
        headers: {
          username: username,
          userid: userId,
        },
      }
    );

    showModal();

    if(response?.data?.statusCode === "000") {
      Modal.success({
        title: "Success",
        content: response?.data?.message || 'Update Status Berhasil',
      })
    } else {
      showPendingModal();
    }
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: dataSource?.length,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setPage(pagination.current);
  };

  const handleDeactivateMerchant = (zonid) => {
    console.log("halo oom", zonid);
    setMerchantDeactivateZonid(zonid);
    console.log("halo lagi", zonid);
    deactivateMerchant(zonid);
  };

  const columns = [
    {
      title: "Mitra ID",
      dataIndex: "zonid",
      key: "zonid",
      render: (text) => <a>{text}</a>,
      fixed: "left",
      width: 200,
    },
    {
      title: "Mitra Name",
      dataIndex: "merchantName",
      key: "merchantName",
    },
    {
      title: "Owner",
      dataIndex: "merchantOwner",
      key: "merchantOwner",
    },
    {
      title: "Phone",
      dataIndex: "merchantOwnerPhone",
      key: "merchantOwnerPhone",
    },
    {
      title: "Zon ID",
      dataIndex: "zonid",
      key: "zonid",
    },
    {
      title: "Address",
      dataIndex: "merchantAddress",
      key: "merchantAddress",
    },
    {
      title: "Type",
      dataIndex: "merchantType",
      key: "merchantType",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { approvalStatus, status, zonid }) => (
        <>
          <Tag color={getColorBtn(approvalStatus)} key={approvalStatus}>
            {approvalStatus.toUpperCase()}
          </Tag>
          <Tag color={getColorBtn(status)} key={zonid}>
            {status.toUpperCase()}
          </Tag>
          <a
            onClick={() => {
              setUserStatus({ approvalStatus, status, zonid });
              showModal();
            }}
            style={{ color: "red", textDecoration: "underline" }}
          >
            Edit
          </a>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space size="small" direction="vertical">
          <Button
            variant="danger"
            size="small"
            style={{ marginLeft: "5px", fontSize: "11px" }}
            onClick={() =>
              history.push({
                pathname: "merchant-edit/" + record?.zonid,
                state: { zonid: record?.zonid },
              })
            }
          >
            View Detail
          </Button>
          <Button
            style={{ marginLeft: "5px", fontSize: "11px", background: "black", borderColor: "white", size: "small" }}
            onClick={() => handleDeactivateMerchant(record?.zonid)}
          >
            Close Account
          </Button>
        </Space>
      ),
    },
  ];

  const handleMenuClick = (e, type) => {
    type === "approvalStatus"
      ? setFilterParams({ ...filterParams, approvalStatus: e.key })
      : setFilterParams({ ...filterParams, accountStatus: e.key });
    const filteredData = dataSource.filter((item) =>
      item[type].toLowerCase() === (e.key)
    );
    
    setDataSource(filteredData);
  };

  const menu = (
    <Menu
      onClick={(e) => handleMenuClick(e, "approvalStatus")}
      items={[
        {
          label: "Waiting",
          key: "waiting",
          icon: <UserOutlined />,
        },
        {
          label: "Approved",
          key: "approved",
          icon: <UserOutlined />,
        },
        {
          label: "Rejected",
          key: "rejected",
          icon: <UserOutlined />,
        },
        {
          label: "Freezed",
          key: "freezed",
          icon: <UserOutlined />,
        },
      ]}
    />
  );

  const accountStatusMenu = (
    <Menu
      onClick={(e) => handleMenuClick(e, "status")}
      items={[
        {
          label: "Waiting",
          key: "waiting",
          icon: <UserOutlined />,
        },
        {
          label: "Active",
          key: "active",
          icon: <UserOutlined />,
        },
        {
          label: "Inactive",
          key: "inactive",
          icon: <UserOutlined />,
        },
        {
          label: "Blacklisted",
          key: "blacklisted", 
          icon: <UserOutlined />,
        }
      ]}
    />
  )

  // console.log({ remapData });

  return (
    <div>
      <div style={{ minHeight: "100vh", backgroundColor: "whiteSmoke" }}>
        <Topbar />
        <Container
          style={{
            minHeight: "100vh",
            backgroundColor: "whiteSmoke",
            marginTop: "2rem",
          }}
        >
          <PageTitle title="All Merchant" canBack={false} />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Search
              placeholder="Search"
              style={{
                width: "481px",
                boxShadow: "0px 7px 52px rgba(222, 222, 222, 0.6)",
                borderRadius: "20px",
              }}
              onSearch={(value) => {
                const filteredData = remapData.filter((item) =>
                  item?.merchantName
                    ?.toLowerCase()
                    .includes(value.toLowerCase())
                );
                setDataSource(filteredData);
              }}
            />
            <Space style={{ marginBottom: 16 }}>
              <DatePicker
                placeholder="Created From"
                name="created_from"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 7px 32px rgba(222, 222, 222, 0.6)",
                  height: "35px",
                }}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    createdAt: value
                  })
                }}
              />
              <DatePicker
                placeholder="Created To"
                name="created_to"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 7px 32px rgba(222, 222, 222, 0.6)",
                  height: "35px",
                }}
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    createFrom: value
                  })
                }}
              />
              <Dropdown overlay={menu}>
                <Button style={{ background: "#fff", color: "#B2121B" }}>
                  <Space>
                    <span>
                      {filterParams?.approvalStatus || "Approval Status"}
                    </span>
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>

              <Dropdown overlay={accountStatusMenu}>
                <Button style={{ background: "#fff", color: "#B2121B" }}>
                  <Space>
                    <span>
                      {filterParams?.accountStatus || "Account Status"}
                    </span>
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>

              <Button
                onClick={() => {
                  setFilterParams({
                    approvalStatus: "",
                    accountStatus: "",
                    createdAt: "",
                    updatedAt: "",
                  });

                  setDataSource(remapData);
                }}
              >
                Clear
              </Button>
              <Modal
                open={isModalOpen}
                onOk={showModal}
                onCancel={showModal}
                footer={null}
                // style={{borderRadius: "999rem", width:"50%"}}
                style={{ borderRadius: "999rem" }}
                // width="200"
              >
                <h6
                  style={{
                    display: "flex",
                    marginBottom: "3rem",
                    justifyContent: "center",
                  }}
                >
                  Edit Merchant Status
                </h6>
                <Form layout="vertical" form={form} onFinish={onStatusChange}>
                  <Row gutter={45}>
                    <Col>
                      <Form.Item
                        label="Current Approval Status"
                        name="approval_status"
                        initialValue={userStatus?.approvalStatus}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        label="Current Active Status"
                        name="active_status"
                        initialValue={userStatus?.status}
                      >
                        <Select>
                          <Option value="Active">Active</Option>
                          <Option value="Blacklisted">Blacklisted</Option>
                          <Option value="Inactive" disabled="true">Inactive</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            gap: "0.5rem",
                          }}
                        >
                          <AntdButton
                            danger={true}
                            onClick={() => setIsModalOpen(!isModalOpen)}
                            variant="danger"
                            htmlType="button"
                            style={{ borderRadius: "18px" }}
                          >
                            Cancel
                          </AntdButton>
                          <AntdButton
                            type="primary"
                            htmlType="submit"
                            style={{ borderRadius: "18px" }}
                          >
                            Save
                          </AntdButton>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Space>
          </div>

          <Card style={{ marginBottom: "2rem" }}>
            <Card body>
              <Button
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  marginBottom: "2rem",
                }}
                onClick={() => history.push("merchant-add")}
              >
                Add New
              </Button>
              <Table
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 1300 }}
                loading={isLoading}
              />
            </Card>
          </Card>
        </Container>
        {/* Modal Action */}

        {/** Pending Modal */}
        <Modal
          open={pendingModal}
          onOk={showPendingModal}
          footer={null}
          style={{ borderRadius: "999rem" }}
        >
          <div
            style={{
              display: "flex",
              marginBottom: "1rem",
              justifyContent: "center",
            }}
          >
            <Form layout="vertical" onFinish={showPendingModal}>
              <Row gutter={45}>
                <img
                  src={warning}
                  style={{
                    margin: "auto",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                />

                {/* <p align="center">Pending Merchant Approval</p> */}
                <p align="center">
                  <span
                    style={{
                      marginBottom: "1rem",
                      marginTop: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Pending Merchant Approval
                  </span>
                  <br />
                  <br />
                  This Merchant is still waiting for approval. To edit status,
                  please wait until approval process is done.
                </p>
                <AntdButton
                  variant="danger"
                  htmlType="submit"
                  style={{
                    marginTop: "1rem",
                    width: "25%",
                    margin: "auto",
                    borderRadius: "18px",
                  }}
                >
                  Close
                </AntdButton>
              </Row>
            </Form>
          </div>
        </Modal>
        {/* End of Modal Action */}
        <Footer />
      </div>
    </div>
  );
};

export default MemberList;
