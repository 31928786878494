import { Button, Divider, Form, Image, Input, List, message, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRequest } from '../../hooks/useRequest';

const checkImageDimension = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target?.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl;

      image.addEventListener('load', () => {
        const { width, height } = image;

        return resolve({
          width,
          height,
        });
      });
    });
  });
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FormBanner = ({ popup }) => {
  const [previewImage, setPreviewImage] = useState('');

  const [cookies] = useCookies(['udata']);
  const { userId, username } = cookies.udata;
  const [form] = Form.useForm();

  const { data: banners, refetch } = useRequest('/merchants/banners');

  const onChange = async (info) => {
    const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (info?.fileList?.length < 1 || !acceptedFileTypes.includes(info?.file?.type)) return;
    const imgUrl = await getBase64(info.file);
    setPreviewImage(imgUrl);
  };

  const getValueFromEvent = (e) => {
    return e;
  };

  const onFinish = async (values) => {
    const { width, height } = await checkImageDimension(values?.banner?.file);

    if (width > 700 || height > 300) {
      message.error('Image must be 700px x 300px');
      return;
    }

    const formData = new FormData();
    formData.append('banner', values.banner?.file);
    formData.append('description', values?.description);

    const data = await axios.post(`${process.env.REACT_APP_MAIN_API}/merchants/banners`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        userid: userId,
        username,
      },
    });

    if (data.status < 300) {
      popup.success({
        message: 'Success',
        description: 'Banner has been uploaded',
        placement: 'top',
      });

      form.resetFields();
      setPreviewImage('');
      refetch();
    }
  };

  const onClickDelete = async (item) => {
    const data = await axios.delete(
      `${process.env.REACT_APP_MAIN_API}/merchants/banners?id=${item.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          userid: userId,
          username,
        },
      },
    );

    if (data.status < 300) {
      refetch();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={banners?.data?.data ?? []}
        renderItem={(item, index) => (
          <List.Item
            key={item.id}
            actions={[
              <Button key="list-loadmore-edit" onClick={() => onClickDelete(item)}>
                Delete
              </Button>,
            ]}
            extra={<Image width={272} alt="logo" src={item?.url_image} />}
          >
            <List.Item.Meta title={<p>Banner {index + 1}</p>} />
            {item.description}
          </List.Item>
        )}
      />

      <Divider>
        <h2>Upload New Banner</h2>
      </Divider>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          name={'banner'}
          rules={[
            {
              required: true,
              message: 'Please upload banner image',
            },
          ]}
          label={'Banner Image'}
          valuePropName={'banner'}
          getValueFromEvent={getValueFromEvent}
        >
          <Upload
            name="banner"
            accept="image/*"
            onChange={onChange}
            beforeUpload={(file) => {
              if (file?.type !== 'image/jpeg' && file?.type !== 'image/png') {
                message.error(`${file.name} is not an image file`);
                return;
              }
              return false;
            }}
            maxCount={1}
            onRemove={() => setPreviewImage('')}
          >
            <Button>Upload Banner</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          labelAlign="right"
          rules={[
            {
              required: true,
              message: 'Please input banner description',
            },
          ]}
          label="Banner Description"
          name="description"
        >
          <Input placeholder="Banner Description" />
        </Form.Item>

        <Image src={previewImage} width={200} />

        <Form.Item>
          <Button type="primary" htmlType="submit" className="mt-4">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormBanner;
