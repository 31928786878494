import { Router } from "react-router-dom";
import Route from "./router";
// import {history} from './utilities'
import { CookiesProvider } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./styles/App.css";
import { MerchantProvider } from "./context/context";

function App() {
  return (
    <MerchantProvider>
      <CookiesProvider>
        <Route />
      </CookiesProvider>
    </MerchantProvider>
  );
}

export default App;
