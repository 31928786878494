import {
    Homepage, 
    MemberDetail,
    MemberList, 
    Login,
    Logs,
    AuditLogs,
    MerchantConfig
} from  '../../pages'
import MerchantAdd from '../../pages/merchantAdd'
import MerchantEdit from '../../pages/merchantEdit'

export const routesSource = [
    {component: Login, path: '/', exact: true, key: 'LOGIN'},
    {component: Login, path: '/login', exact: true, key: 'LOGIN'},    
    {
        path: '',
        child: [
            {
                component: MemberList,
                path: '/home',
                exact: true,
                private: true,
                key: 'HOMEPAGE',
            },
            {
                component: MemberDetail,
                path: '/detail',
                exact: true,
                private: true,
                key: 'MEMBERDETAIL',
            },
            {
                component: Logs,
                path: '/logs',
                exact: true,
                private: true,
                key: 'LOGS',
            },  
            {
                component: MerchantAdd,
                path: '/merchant-add',
                exact: true,
                private: true,
                key: 'MERCHANT_ADD',
            },                                  
            {
                component: MerchantEdit,
                path: '/merchant-edit/:id',
                exact: true,
                private: true,
                key: 'MERCHANT_EDIT',
            },
            {
                component: AuditLogs,
                path: '/auditlogs',
                exact: true,
                private: true,
                key: 'AUDITLOGS',
            },        
            {
                component: MerchantConfig,
                path: '/merchant-config',
                exact: true,
                key: 'MERCHANT_CONFIG',
            }                           
        ]
    }
    
]