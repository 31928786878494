import { Card, Form, Input, Button, InputNumber, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Topbar } from '../../components';
import { useRequest } from '../../hooks/useRequest';
import FormBanner from './FormBanner';
import FormInfo from './FormInfo';

const tabList = [
  {
    key: 'fee',
    tab: 'Merchant Withdraw',
  },
  {
    key: 'info',
    tab: 'Form Info',
  },
  {
    key: 'banner',
    tab: 'Form Banner',
  }
];

const MerchantConfig = () => {
  const [activeTabKey1, setActiveTabKey1] = useState('fee');

  const [form] = Form.useForm();

  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };

  const { data, isLoading } = useRequest('/merchants/config/withdrawalFee');

  const [cookies] = useCookies(['udata']);
  const { userId, username } = cookies.udata;

  useEffect(() => {
    if (data?.statusCode === '000') {
      form.setFieldsValue({
        withdrawalFee: data?.data?.data?.withdrawalFee,
        minWithdrawal: data?.data?.data?.minWithdrawal,
      });
    }
  }, [data]);

  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values) => {

    const data = await axios
      .post(`${process.env.REACT_APP_MAIN_API}/merchants/config/withdrawalFee`, values, {
        headers: {
          'Content-Type': 'application/json',
          userid: userId,
          username: username,
        },
      })
      .catch((err) => console.log({ err }));

    if (data?.data?.statusCode === '000') {
      api.success({
        message: 'Success',
        description: 'Merchant Withdrawal Fee Updated',
        placement: 'top',
      });

      form.setFieldsValue({
        withdrawalFee: values?.withdrawalFee,
        minWithdrawal: values?.minWithdrawal,
      });
    }

  };

  const contentList = {
    fee: (
      <div>
        <Form
          name="basic"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          className="flex flex-col justify-center items-center"
          // style={{ maxWidth: 600 }}
          initialValues={{ withdrawalFee: data?.data?.data?.withdrawalFee, minWithdrawal: 0 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Withdrawal Fee"
            name="withdrawalFee"
            rules={[{ required: true, message: 'Please input Withdrawal Fee!' }]}
          >
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              disabled={isLoading}
              style={{ width: '10rem' }}
              // defaultValue={data?.data?.data?.withdrawalFee}
              // type={'number'}
            />
          </Form.Item>

          <Form.Item
            label="Min Withdrawal"
            name="minWithdrawal"
            rules={[{ required: true, message: 'Please input minimum withdrawal!' }]}
          >
            <InputNumber
              disabled={isLoading}
              style={{ width: '10rem' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    ),
    info: <FormInfo />,
    banner: <FormBanner popup={api} />
  };

  return (
    <div>
      <Topbar />
      <Container className="mt-4">
        {contextHolder}
        <Card
          style={{
            width: '100%',
          }}
          title="Merchant Config"
          tabList={tabList}
          activeTabKey={activeTabKey1}
          onTabChange={(key) => {
            onTab1Change(key);
          }}
        >
          {contentList[activeTabKey1]}
        </Card>
      </Container>
    </div>
  );
};

export default MerchantConfig;
