import React from 'react'
import {Redirect, Route} from 'react-router-dom';
import {Cookies} from 'react-cookie'

export const AuthRoute = ({component, ...rest}) => {
    const cookies = new Cookies()
    const authData = cookies.get('udata')
    const isAuthenticated = authData?.token
    if (!isAuthenticated && rest?.private) {
      return <Redirect to="/login" />;
    }
    if (isAuthenticated && (rest.path === '/login' || rest.path === '/')) {
      return <Redirect to="/home" />;
    }
    return (
      <div>
        <Route
          {...rest}
          exact
          render={(props) => {
            return React.createElement(component, props);
          }}
        />
      </div>
    );
  };
  