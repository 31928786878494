import React, { useRef, useState, useCallback, useMemo, useEffect } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import {
  Topbar,
  PageTitle,
  Footer,
  LogsTable,
  Filters,
  Searchbar,
} from "../../components";
import { columnsMaker } from "../../utilities";
import Services from "../../services";
import { useCookies } from "react-cookie";
import { Tabs } from "antd";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import { useRequest } from "../../hooks/useRequest";
import { useLocation,useParams } from "react-router-dom";

const MerchantEdit = () => {
  // Get userId from cookie
  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;
  const [active, setActive] = useState("1");
  const [merchantDetail, setmerchantDetail] = useState([]);

  const { data: regisParams, isLoading } = useRequest("/config/registParams");
  const { id: zonid } = useParams();

  // const location = useLocation();
  // console.log("Zonid: ",location.state)
  
  useEffect(async() => {
    const merchantDetail = await fetch(
      `${process.env.REACT_APP_MAIN_API}/merchants/${zonid}`,
      {
        headers: {
          "Content-Type": "application/json",
          userid: process.env.API_USERID,
          username: process.env.API_USERNAME,
        },
      })
      const result = await merchantDetail.json();
     
      setmerchantDetail(result?.data);
      console.log("Res", result.data);
    }, [])

  const onPrev = () => {
    let nextPage = Number(active) - 1;
    setActive(String(nextPage));
    // console.log(nextPage);
  };

  const onClick = () => {  
    if (Number(active) >= tabItems.length) return;

    let nextPage = Number(active) + 1;
    setActive(String(nextPage));
    // console.log(nextPage);
  };

  const onChange = (activeKey) => {
    console.log(activeKey);
    setActive(activeKey);
  };

  if (!merchantDetail) {
    return (
      <h1>No Data</h1>
    )
  }

  const tabItems = [
    {
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "2rem" }}>1</span>
          <span>Primary Data</span>
        </div>
      ),
      key: "1",
      disabled: true,
      children: <Tab1 onClickedNext={onClick} merchantDetail={merchantDetail} zonid={zonid} />
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "2rem" }}>2</span>
          <span>PIC Data</span>
        </div>
      ),
      key: "2",
      disabled: true,
      children: <Tab2 onClickedNext={onClick} onPrevious={onPrev} registerParam={regisParams?.data} merchantDetail={merchantDetail} zonid={zonid} />,
    },
    { 
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "2rem" }}>3</span>
          <span>Merchant Data</span>
        </div>
      ),
      key: "3",
      disabled: true,
      children: <Tab3 onClickedNext={onClick} registerParam={regisParams?.data} merchantDetail={merchantDetail} zonid={zonid} onPrevious={onPrev} />,
    },
    { 
      label: (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span style={{ fontWeight: "bold", fontSize: "2rem" }}>4</span>
          <span>Additional Data</span>
        </div>
      ),
      key: "4",
      disabled: true,
      children: <Tab4 registerParam={regisParams?.data} merchantDetail={merchantDetail} zonid={zonid} onPrevious={onPrev} />,
    },
  ];

  return (
    <div>
      <div style={{ minHeight: "100vh", backgroundColor: "whiteSmoke" }}>
        <Topbar />
        <Container
          style={{
            minHeight: "100vh",
            backgroundColor: "whiteSmoke",
            marginTop: "2rem",
          }}
        >
          <PageTitle title="Edit Merchant" canBack={true} />
          <Card style={{ marginBottom: "2rem" }}>
            <Card body>
              <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                activeKey={active}
                items={tabItems}
              />
              {/* <Button onClick={onClick}>Next</Button> */}
            </Card>
          </Card>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default MerchantEdit;
