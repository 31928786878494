const columnsMaker = (data) => {
    let columns = []
    for (const key in data[0]) {
        columns.push({
            Header: () => <div style={{whiteSpace: "nowrap"}}>{key.replace(/_/g, ' ').toUpperCase()}</div>,
            accessor: key
        })
    }
    return columns
}

const objectParser = (data) => {
    let source = data.data
    let alamat = source.alamat_usaha ?? null
    let jam_operasional = source.jam_operasional ?? null

    if(alamat){alamat = `${alamat.address_1}, ${alamat.district}, ${alamat.city}, ${alamat.province}`}
    if(jam_operasional){jam_operasional = `${jam_operasional.open} - ${jam_operasional.close}`}


    delete source.alamat_usaha
    delete source.jam_operasional

    source.alamat = alamat
    source.jam_operasional = jam_operasional

    return source
}

const getColorBtn = (status) => {
    let color 

    switch(status.toLowerCase()){
      case "approved":
        color = "green"
        break;
      case "active":
        color = "blue"
        break;
      case "waiting":
        color = "yellow"
        break;
      case "inactive":
        color = "grey"
        break;
      case "rejected":
        color = "brown"
        break;
      case "blacklisted":
        color = "black"
        break;
      case "edit":
        color = "light grey"
      default:
        color = "red"
        break;
    }

    return color
}

export {
    columnsMaker,
    objectParser,
    getColorBtn
}