import React, {useEffect, useState, useCallback} from 'react';
import {Container,Row, Col, Alert} from 'react-bootstrap'
import {Topbar, PageTitle, ProfileCard, ProfileTabs, Footer} from '../../components'
import {history} from '../../utilities'
import {useLocation} from 'react-router-dom'
import Services from '../../services'
import {useCookies} from 'react-cookie'

const MemberDetail = () => {
    // const {mid} = useParams()
    const location = useLocation()
    const [userData, setuserData] = useState({})
    const [alert, setalert] = useState(0)
    const [alertFeature, setalertFeature] = useState(0)
    const [isLoadingBtn, setisLoadingBtn] = useState({
        kirimUang: false,
    })

    // Get userId from cookie
    const [cookies] = useCookies(['udata']);
    const {userId, username} = cookies.udata;

    useEffect(() => {
        if(location?.state){
            const params = {
                detail: location?.state?.mid
            }
            try {
                Services.Get('/members', params, userId, username).then(
                    (data) => {
                        if(data.statusCode === '000'){
                            // let parsed = await objectParser(data)
                            // console.log(data)
                            setuserData(data.data)
                            setalert(1)
                            setTimeout(() => {
                                setalert(0)
                            }, 5000);
                        }else if(data.statusCode === '001'){
                            setalert(2)
                            setTimeout(() => {
                                setalert(0)
                            }, 5000);
                        }
                    }
                )
            } catch (error) {
                console.log(error)
                setalert(2)
                setTimeout(() => {
                    setalert(0)
                }, 5000);
            }
        }else{
            history.push('/home')
        }
        return () => {
            setuserData([])
        }
    }, [])

    const ActivateFeatures = (feature) => {
        switch (feature) {
            case "KirimUang":
                {
                    // console.log(`Activate ${feature} Coming Soon...`)
                    setisLoadingBtn(prevState => ({...prevState, ['kirimUang']: true}));
                    let request = {
                        zonid: userData?.zonid
                    }
                    Services.Post('/kirim-uang', request, userId, username)
                        .then((response) => {
                            if(response?.statusCode === '000'){
                                setalertFeature(1)
                                setTimeout(() => {
                                    history.go(0);
                                    setalertFeature(0)
                                    setisLoadingBtn(prevState => ({...prevState, ['kirimUang']: false}));
                                }, 3000);
                            }else{
                                setalertFeature(2)
                                setTimeout(() => {
                                    setalertFeature(0)
                                    setisLoadingBtn(prevState => ({...prevState, ['kirimUang']: false}));
                                }, 3000);
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setalertFeature(2)
                            setTimeout(() => {
                                setalertFeature(0)
                            }, 3000);
                        })
                }
                break;

            default:
                break;
        }
    }

    // const DeactivateFeatures = (feature) => {
    //     console.log(`Deactivate ${feature} Coming Soon...`)
    // }

    return (
    <div style={{minHeight: '100vh', backgroundColor: 'whiteSmoke'}}>
        <Topbar/>
        <Container style={{minHeight: '100vh', backgroundColor: 'whiteSmoke', marginTop: "2rem"}}>
        <PageTitle title="Merchant Detail" canBack={true} path="/home"/>
        {alert === 1 && <Alert variant='success' dismissible>Member data fetched!</Alert>}
        {alert === 2 && <Alert variant='danger' dismissible>Failed to fetched member data!</Alert>}
        {alertFeature === 1 && <Alert variant='success' dismissible>Action success!</Alert>}
        {alertFeature === 2 && <Alert variant='danger' dismissible>Action failed! Try Again Later.</Alert>}
        <Row>
            <Col md={4}>
                <ProfileCard userData={userData}/>
            </Col>
            <Col md={8}>
                <ProfileTabs 
                    userData={userData}
                    ActivateFeatures={ActivateFeatures}
                    // DeactivateFeatures={DeactivateFeatures}
                    isLoadingBtn={isLoadingBtn}
                />
            </Col>
        </Row>
        </Container>
        <Footer />
    </div>
    )
}

export default MemberDetail

