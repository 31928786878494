import React from 'react';
import { Navbar, Col, Button, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo/logo.png';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import Services from '../../services';

const Topbar = () => {
  // Browser history object
  let history = useHistory();
  // Get userData from cookie
  const [cookies, removeCookie] = useCookies(['udata']);
  const userId = cookies.udata?.userId;
  const username = cookies.udata?.username;

  const handleLogout = async () => {
    removeCookie('udata', { path: '/' });
    history.push('/');
  };

  return (
    <div>
      <Navbar bg="danger" variant="dark" expand="md">
        <Col md={8}>
          <Navbar.Brand href="/home">
            <img alt="" src={logo} width="30" height="30" className="d-inline-block align-top" />{' '}
            IKIPAY - Merchant Approval
          </Navbar.Brand>
        </Col>
        <Col md={4}>
          {userId ? (
            <>
              <Button
                variant="info"
                onClick={() => history.push('/merchant-config')}
                style={{ marginRight: '10px' }}
              >
                Merchant Config
              </Button>
              <Button
                variant="info"
                onClick={() => history.push('/auditlogs')}
                style={{ marginRight: '10px' }}
              >
                Audit Logs <FontAwesomeIcon icon={faClipboardList} />
              </Button>

              <Button variant="warning" onClick={handleLogout}>
                Logout <FontAwesomeIcon icon={faSignOutAlt} />
              </Button>
            </>
          ) : (
            ''
          )}
        </Col>
      </Navbar>
    </div>
  );
};

export default Topbar;
