import { Button, Form, Input, Radio, Row, Col, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useMerchant } from "../../hooks/useMerchant";
import axios from "axios";
import { useHistory } from "react-router-dom";
import warning from '../../assets/warning.png';

const { Option, OptGroup } = Select;

const Tab1 = ({ onClickedNext, merchantDetail, zonid }) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [disable, setDisable] = useState(true);
  const [pendingModal, setPendingModal] = useState(false);
  const [namaUsaha, setNamaUsaha] = useState(merchantDetail.merchantName);

  const { state, dispatch } = useMerchant();
  const [businessType, setBusinessType] = useState("");

  const showPendingModal = () => {
    setPendingModal(!pendingModal);
  };

  const onBusinessTypeChange = async (value) => {
    setBusinessType(value);
  };

  useEffect(() => {
    setBusinessType(merchantDetail.merchantBusinessType)
  }, [])

  const onFinish = async (values) => {
    dispatch({ type: "primary_data", payload: values });
    console.log({ values });

    let payload = {
      merchantName: values.nama_usaha,
      ownerName: values.nama_pemilik,
      businessType: values.businessType,
      ownerNpwp: values.npwp_pemilik,
      ownerPhone: "0"+values.nomor_hp,
      ownerEmail: values.email
    }

    const response = await axios.patch(process.env.REACT_APP_MAIN_API + "/merchants?updateType=primary&zonid="+zonid, payload, {
      headers: {
        username: process.env.API_USERNAME,
        userid: process.env.API_USERID
      }
    })

    console.log("Response Update Primary :",response)
    if(response?.data?.statusCode === "000") {
      console.log("Sukses Update Tab 1")
      showPendingModal();
      setDisable(!disable);
      // window.location.reload()
    }
  };

  const updatePrimary = async () => { 
    let payload = {
      merchantName: form.getFieldValue('nama_usaha'),
      ownerName: form.getFieldValue('nama_pemilik'),
      ownerPhone: "0"+form.getFieldValue('nomor_hp'),
      ownerEmail: form.getFieldValue('email')
    }

    const response = await axios.patch(process.env.REACT_APP_MAIN_API + "/merchants?updateType=primary&zonid="+zonid, payload, {
      headers: {
        username: process.env.API_USERNAME,
        "user_id": process.env.API_USERID
      }
    })

    console.log("Response Update Primary :",response)
    if(response?.data?.statusCode === "000") {
      console.log("Sukses Update Tab 1")
      window.location.reload()
    }
  }

  //Standarize Phone
  if(merchantDetail?.merchantOwnerPhone){
    if(merchantDetail.merchantOwnerPhone.charAt(0) == "0") merchantDetail.merchantOwnerPhone.replace("0","")
    merchantDetail.merchantOwnerPhone = merchantDetail.merchantOwnerPhone.replace("+62","")
  }
  
  form.setFieldsValue({
      nama_usaha: merchantDetail?.merchantName,
      nama_pemilik: merchantDetail.merchantOwnerName,
      businessType: merchantDetail.merchantBusinessType,
      npwp_pemilik: merchantDetail.merchantNpwp,
      nomor_hp:merchantDetail.merchantOwnerPhone,
      email: merchantDetail.merchantOwnerEmail
  });
  
  return (
    <div>
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={{
        types: {
          email: "that is not valid email!",
        },
      }}
    >
      <Row gutter={45}>
        <Col span={8}>
          <Form.Item
            label="Nama Usaha"
            // initialValue={null}
            name="nama_usaha"
            rules={[{ required: true, message: "nama usaha is required" }]}
          >
            <Input placeholder="input placeholder" disabled={disable} />
          </Form.Item>
          <Form.Item
            label="Nama Pemilik"
            name="nama_pemilik"
            rules={[{ required: true, message: "pls insert" }]}
          >
            <Input placeholder="input placeholder" disabled={disable} />
          </Form.Item>

          
          <Form.Item
            label="Jenis Usaha"
            name="businessType"
            rules={[{ required: true, message: "Jenis Usaha is required" }]}>
            <Select placeholder="Jenis Usaha" onChange={onBusinessTypeChange} disabled={disable}>
              <Option value="Perorangan">Perorangan</Option>
              <Option value="BadanUsaha">Badan Usaha</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="NPWP Perusahaan"
            name="npwp_pemilik"

            rules={[{ required: businessType != 'Perorangan' ? true : false, message: "npwp Pemilik is required" }]}
          >
            <Input disabled = {businessType === 'Perorangan' && disable ? true: false} placeholder="" />
          </Form.Item>

          <Form.Item
            label="Nomor HP"
            name="nomor_hp"
            rules={[{ required: true, message: "pls insert" }]}
          >
            <Input
              placeholder="input placeholder"
              addonBefore="+62"
              disabled={disable}
            />
          </Form.Item>
          <Form.Item
            label="Email (Opsional)"
            name="email"
            rules={[{ type: "email" }]}
          >
            <Input placeholder="input placeholder" disabled={disable} />
          </Form.Item>
        </Col>

        <Col >
          <a onClick={() => { setDisable(!disable) }}>Edit</a>
        </Col>
      </Row>

      <Row justify="end">
        <Button onClick={() => { history.goBack()}}>Cancel</Button>
        {
          (disable)?<Button htmlType="button" className="ml-3" onClick={onClickedNext}>Next</Button>:<Button htmlType="submit" className="ml-3">Save</Button>
        }
      </Row>

      {/** Pending Modal */}
      <Modal
          open={pendingModal}
          onOk={showPendingModal}
          footer={null}
          style={{ borderRadius: "999rem" }}
        >
          <div style={{
              display: "flex",
              marginBottom: "1rem",
              justifyContent: "center",
            }}>
              <Form layout="vertical" onFinish={showPendingModal}>
                <Row gutter={45}>
                  <img src={warning} style={{ margin:"auto", marginBottom: "1rem",marginTop:"1rem"}} />
      
                  {/* <p align="center">Pending Merchant Approval</p> */}
                  <p align="center"><span style={{marginBottom: "1rem",marginTop:"1rem", fontWeight:"bold"}}>Pending Merchant Approval</span><br /><br /><span syle={{marginLeft: "1rem", marginRight: "1rem"}}>This Merchant is still waiting for approval. To edit status, please wait until approval process is done.</span></p>
                  <Button variant="danger" htmlType="submit" style={{ marginTop:"1rem", width:"25%",margin:"auto", borderRadius: "18px"}}>Close</Button>

                </Row>
              </Form>
          </div>
        </Modal>
        {/* End of Modal Action */}
    </Form>
    </div>
  );
};

export default Tab1;
