import { Button, Form, Input, Radio, Row, Col, Select } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMerchant } from "../../hooks/useMerchant";
import debounce from 'awesome-debounce-promise';
import { useCookies } from 'react-cookie';

const { Option, OptGroup } = Select;



const Tab1 = ({ onClickedNext }) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;
  const { state, dispatch } = useMerchant();
  const [businessType, setBusinessType] = useState("");



  const onFinish = async (val) => {

    console.log("run")
    dispatch({ type: "primary_data", payload: val });

    onClickedNext();

    console.log({ val });
  };

  const checkPhoneNumber = async (phone) => {
    const res = await fetch(process.env.REACT_APP_MAIN_API + '/config/ikipayStatus?phonenumber=0' + phone, {
      headers: {
        'Content-Type': 'application/json',
        username: username,
        userid: userId
      }
    })
    const result = await res.json()

    console.log({result})

    return result
  }

  const onBusinessTypeChange = async (value) => {
    setBusinessType(value);
  };

  

  const debouncedFetch = debounce(checkPhoneNumber, 500)

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={{
        types: {
          email: "that is not valid email!",
        },
      }}
    >
      <Row gutter={45}>
        <Col span={8}>
          <Form.Item
            label="Nama Usaha"
            name="nama_usaha"
            // initialValue={null}
            rules={[
              { required: true, message: "nama usaha is required" }
            ]}
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Nama Pemilik"
            name="nama_pemilik"
            rules={[{ required: true, message: "Nama Pemilik is required" }]}
          >
            <Input placeholder="input placeholder" />
          </Form.Item>

          <Form.Item
            label="Jenis Usaha"
            name="businessType"
            rules={[{ required: true, message: "Jenis Usaha is required" }]}>
            <Select placeholder="Jenis Usaha" onChange={onBusinessTypeChange}>
              <Option value="Perorangan">Perorangan</Option>
              <Option value="BadanUsaha">Badan Usaha</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="NPWP Perusahaan"
            name="npwp_pemilik"

            rules={[{ required: businessType != 'Perorangan' ? true : false, message: "npwp Pemilik is required" }]}
          >
            <Input disabled = {businessType === 'Perorangan'} placeholder="input placeholder" />
          </Form.Item>

          <Form.Item
            label="Nomor HP"
            name="nomor_hp"
            rules={[
              { required: true, message: "Nomor HP is required" },
              ({ getFieldValue}) => ({
                async validator(rule, value) {
                  //manipulasi value nya disini
                  const isValid = await debouncedFetch(value)
                  console.log({isValid})
                  if (isValid?.statusCode !== "000") {
                    return Promise.reject(isValid?.message || "Nomor belum terdaftar");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="input placeholder" addonBefore="+62" />
          </Form.Item>
          <Form.Item
            label="Email (Opsional)"
            name="email"
            rules={[{ type: "email" }]}
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
        </Col>
        
      </Row>

      <Row justify="end">
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </Button>
        <Button htmlType="submit" className="ml-3">
          Next
        </Button>
      </Row>
    </Form>
  );
};

export default Tab1;
