import React from "react";
import styled from "@emotion/styled";
import colors from "./theme";

const Row = ({ label, ...props }) => {
  return (
    <StyledRow>
      <StyledFormLabel>{label}</StyledFormLabel>
      {props.children}
    </StyledRow>
  );
};

export default Row;

const StyledFormLabel = styled("span")`
  font-family: "Helvetica";
  font-weight: 700;
  color: ${colors.grey[800]};
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 6px;
`;

const StyledRow = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;
