import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Stack,
  Container,
  Card,
  // Modal,
  Alert,
  Spinner,
  Button,
} from "react-bootstrap";
import {
  Topbar,
  PageTitle,
  Footer,
  LogsTable,
  Filters,
  Searchbar,
} from "../../components";
import {
  Space,
  Table,
  Tag,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Row,
  Col,
  Form,
  Select,
  message as popupMessage,
  Button as AntdButton,
} from "antd";

import useSWR from "swr";
import { getColorBtn } from "../../utilities";
import Services from "../../services";
import { useCookies } from "react-cookie";
import { Router, useHistory } from "react-router-dom";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { useRequest } from "../../hooks/useRequest";
import warning from "../../assets/warning.png";
import logo from "../../assets/logo/logo.png";

const { Search } = Input;
const { Option, OptGroup } = Select;

let page = 1;

const AuditLogs = () => {
  // Browser history instance
  let history = useHistory();
  // Get userId from cookie
  const [cookies] = useCookies(["udata"]);
  const { userId, username } = cookies.udata;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(1);

  const [filterParams, setFilterParams] = useState({
    approvalStatus: "",
    accountStatus: "",
    createdAt: "",
    updatedAt: "",
  });

  const checkFilterDate = () => {
    if(filterParams?.createdAt && filterParams?.createFrom){
      console.log("createAt: "+filterParams.createdAt.format('YYYY-MM-DD'))
      console.log("createFrom: "+filterParams.createFrom.format('YYYY-MM-DD'))
      return `&createdAtFrom=${filterParams.createdAt.format('YYYY-MM-DD')}&createdAtTo=${filterParams.createFrom.format('YYYY-MM-DD')}`;
    }

    return "";
  };

  let { data: testData, isLoading } = useRequest('/auditrails?orderBy=desc'+checkFilterDate());

  const remapData = testData?.data?.map((item) => ({
    ...item,
    key: item?.id,
    parameter: item.parameter,
    merchantName: item.parameter?.merchant_name || "",
    updateBefore: item.parameter?.before || "",
    updateAfter: item.parameter?.after || ""
  }));

  console.log({remapData})

  useEffect(() => {
    setDataSource(remapData);
  }, [testData]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
      total: dataSource?.length,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setPage(pagination.current);
  };

  const handleButton = (data) => {
    setJsonData(data)
    setIsModalOpen(!isModalOpen)
  }

  const columns = [
    {
      title: "Log ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
      fixed: "left",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
      render: (data) => (data) ? <Button type="primary" onClick={() => handleButton(data)}>View</Button> : "",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Merchant Name",
      dataIndex: "merchantName",
      key: "merchantName",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
    },
  ];

  return (
    <div>
      <div style={{ minHeight: "100vh", backgroundColor: "whiteSmoke" }}>
        <Topbar />
        <Container
          style={{
            minHeight: "100vh",
            backgroundColor: "whiteSmoke",
            marginTop: "2rem",
          }}
        >
          <PageTitle title="AuditLogs" canBack={false} />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Search
              placeholder="Search"
              style={{
                width: "481px",
                boxShadow: "0px 7px 52px rgba(222, 222, 222, 0.6)",
                borderRadius: "20px",
              }}
              onSearch={(value) => {
                const filteredData = remapData.filter(
                  (item) =>
                    item?.action
                      ?.toLowerCase()
                      .includes(value?.toLowerCase()) ||
                    item?.username
                      ?.toLowerCase()
                      .includes(value?.toLowerCase()) ||
                    item?.zonid?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.id?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.merchantName?.toLowerCase().includes(value?.toLowerCase())
                );
                setDataSource(filteredData);
              }}
            />
            <Space style={{ marginBottom: 16 }}>
              <DatePicker
                placeholder="Created From"
                name="created_from"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 7px 32px rgba(222, 222, 222, 0.6)",
                  height: "35px",
                }}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    createdAt: value,
                  });
                }}
              />
              <DatePicker
                placeholder="Created To"
                name="created_to"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0px 7px 32px rgba(222, 222, 222, 0.6)",
                  height: "35px",
                }}
                onChange={(value) => {
                  setFilterParams({
                    ...filterParams,
                    createFrom: value,
                  });
                }}
              />

              <Button
                onClick={() => {
                  setFilterParams({
                    approvalStatus: "",
                    accountStatus: "",
                    createdAt: "",
                    updatedAt: "",
                  });

                  setDataSource(remapData);
                }}
              >
                Clear
              </Button>
            </Space>
          </div>

          <Card style={{ marginBottom: "2rem" }}>
            <Card body>
              <Table
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 1300 }}
                loading={isLoading}
              />
            </Card>
          </Card>
        </Container>
        <Modal
          title="Detail JSON"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(!isModalOpen)}
          onOk={() => setIsModalOpen(!isModalOpen)}
          width="860px"
        >
          {jsonData?.before && jsonData?.after ? (
            <Row gutter={32}>
              <Col span={12}>
                <h2>Before</h2>
                {<pre>{JSON.stringify(jsonData?.before, null, 2)}</pre>}
              </Col>
              <Col span={12}>
                <h2>After</h2>
                {<pre>{JSON.stringify(jsonData?.after, null, 2)} </pre>}
              </Col>
            </Row>
          ) : (
            <div>
              <pre>{JSON.stringify(jsonData, null, 2)}</pre>
            </div>
          )}
        </Modal>
        <Footer />
      </div>
    </div>
  )
};

export default AuditLogs;
