import {
  Button,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Select,
  message,
  Upload,
  DatePicker,
  Space,
  Image,
  Modal,
  Spin
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useMerchant } from "../../hooks/useMerchant";
import { useRequest } from "../../hooks/useRequest";
import Services from "../../services";
import moment from "moment";
import axios from "axios";
import { useCookies } from 'react-cookie';
import warning from '../../assets/warning.png';

const { Option, OptGroup } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Tab2 = ({ onClickedNext, registerParam, merchantDetail, zonid, onPrevious }) => {
  console.log({merchantDetail})
  //Standarize Phone
  if(merchantDetail?.merchantPicData?.picPhone){
    if(merchantDetail?.merchantPicData?.picPhone.charAt(0) == "0") merchantDetail.merchantPicData.picPhone = merchantDetail.merchantPicData.picPhone.replace("0","")
    merchantDetail.merchantPicData.picPhone = merchantDetail.merchantPicData.picPhone.replace("+62","")
  }

  const { data: provinsiData } = useRequest(
    "/config/locationDetail?idProvinsi=ALL"
  );

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [disable, setDisable] = useState(true);
  const { state, dispatch } = useMerchant();
  const [pendingModal, setPendingModal] = useState(false);
  const [spin, setSpin] = useState(false);

  const [kabupatenList, setKabupatenList] = useState([]);
  const [kecamatanList, setKecamatanList] = useState([]);
  const [kelurahanList, setKelurahanList] = useState([]);

  const [form] = Form.useForm();

  const [cookies] = useCookies(['udata']);
  const {userId, username} = cookies.udata;
  
  const showPendingModal = () => {
    setPendingModal(!pendingModal);
  };

  useEffect(() => {

    console.log("DEFAULT PROVINSI :"+ merchantDetail.merchantPicData.province.code+" - "+ merchantDetail.merchantPicData.province.label)
    form.setFieldsValue({
      nama_pic: merchantDetail.merchantPicData.picName,
      nomor_hp: merchantDetail.merchantPicData.picPhone,
      status_kepemilikan:merchantDetail.merchantPicData.merchantPicOwnershipStatus,
      nomor_ktp: merchantDetail.merchantPicData.noktp,
      tempat_lahir: merchantDetail.merchantPicData.birthOfPlace,
      tgl_lahir: moment(new Date(merchantDetail.merchantPicData.dateOfBirth)),
      // provinsi: provinsiData?.data?.find(item => item?.namaProvinsi === merchantDetail?.merchantPicData?.province)?.idProvinsi || "",
      provinsi: merchantDetail.merchantPicData.province.label,
      kabupaten: merchantDetail.merchantPicData.city.label,
      kecamatan: merchantDetail.merchantPicData.district.label,
      kelurahan: merchantDetail.merchantPicData.suburb.label,
      rt: merchantDetail.merchantPicData.rt,
      rw: merchantDetail.merchantPicData.rw,
      alamat: merchantDetail.merchantPicData.address,
      agama: merchantDetail.merchantPicData.religion,
      pendidikan_terakhir: merchantDetail.merchantPicData.educationLevel,
      status_perkawinan: merchantDetail.merchantPicData.maritalStatus,
      // foto_ktp:merchantDetail.picktp
    });
  }, [provinsiData])
  
  const onProvinceChange = async (value) => {
    const kabupatenData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idProvinsi=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kabupatenData.json();
    setKabupatenList(result?.data);
  };

  const onKabupatenChange = async (value) => {
    const kecamatanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKotaKab=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kecamatanData.json();
    setKecamatanList(result?.data);
  };

  const onKecamatanChange = async (value) => {
    const kelurahanData = await fetch(
      `${process.env.REACT_APP_MAIN_API}/config/locationDetail?idKecamatan=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          username: username,
          userid: userId
        },
      }
    );
    const result = await kelurahanData.json();
    setKelurahanList(result?.data);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    getBase64(file, (result) => {
      setImageUrl(result);
    });

    return false;

    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = async (values) => {
    setSpin(true)

    try{
      values.foto_ktp = String(imageUrl)?.split(",")[1]

      dispatch({ type: "pic_data", payload: values });

      let payload = {
        picName: values.nama_pic,
        picPhone: "0"+values.nomor_hp,
        merchantPicOwnershipStatus: values.status_kepemilikan,
        noktp: values.nomor_ktp,
        birthOfPlace: values.tempat_lahir,
        dateOfBirth: values.tgl_lahir,
        province: (merchantDetail.merchantPicData.province.label == values.provinsi) ? merchantDetail.merchantPicData.province.code : values.provinsi,
        city: (merchantDetail.merchantPicData.city.label == values.kabupaten) ? merchantDetail.merchantPicData.city.code : values.kabupaten,
        district: (merchantDetail.merchantPicData.district.label == values.kecamatan) ? merchantDetail.merchantPicData.district.code : values.kecamatan,
        suburb: (merchantDetail.merchantPicData.suburb.label == values.kelurahan) ? merchantDetail.merchantPicData.suburb.code : values.kelurahan,
        rt: values.rt,
        rw: values.rw,
        address: values.alamat,
        religion: values.agama,
        educationLevel: values.pendidikan_terakhir,
        maritalStatus: values.status_perkawinan,
        picKtp: values.foto_ktp,
      }

      const response = await axios.patch(process.env.REACT_APP_MAIN_API + "/merchants?updateType=pic&zonid="+zonid, payload, {
        headers: {
          username: username,
          userid: userId
        }
      })

      if(response?.data?.statusCode === "000") {
        console.log("Sukses Update Tab 2")
        Modal.success({
          title: "Success",
          content: response?.data?.message || 'Update Data Berhasil',
        })
        setDisable(!disable);
        // window.location.reload()
      } else {
        setPendingModal(true)
        setDisable(!disable);
      }
    }catch(err){
      console.log(err)
  
      Modal.error ({
        title: "Error",
        content: err?.message || 'Please Contact Administrator !',
      });
    }finally{
      setSpin(false)
    }
  };

  return (
    <Spin tip="Loading..." spinning={spin}>

      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={45}>
          <Col span={7}>
            <Form.Item
              label="Nama PIC"
              name="nama_pic"
              rules={[{ required: true, message: "nama pic is required" }]}
            >
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="Nomor HP" name="nomor_hp">
              <Input
                addonBefore="+62"
                placeholder="input placeholder"
                disabled={disable}
              />
            </Form.Item>
            <Form.Item label="Statu Kepemilikan" name="status_kepemilikan">
              <Select disabled={disable}>
                {registerParam["merchantPicOwnershipStatus"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Nomor KTP" name="nomor_ktp">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="Tempat Lahir" name="tempat_lahir">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="Tanggal Lahir" name="tgl_lahir">
              <DatePicker style={{ width: "100%" }} disabled={disable} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Provinsi Tempat Tinggal"
              name="provinsi"
              rules={[{ required: true, message: "provinsi is required" }]}
            >
              <Select
                placeholder="Provinsi"
                onChange={onProvinceChange}
                disabled={disable}
              >
                {provinsiData?.data?.map((item, index) => (
                  <Option key={item?.idProvinsi} value={item?.idProvinsi}>
                    {item.namaProvinsi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kabupaten Tempat Tinggal"
              name="kabupaten"
              rules={[{ required: true, message: "kabupaten is required" }]}
            >
              <Select
                placeholder="Kabupaten"
                disabled={kabupatenList.length < 1}
                onChange={onKabupatenChange}
              >
                {kabupatenList?.map((item, index) => (
                  <Option key={item?.idKotaKab} value={item?.idKotaKab}>
                    {item?.namaKotaKab}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kecamatan Tempat Tinggal"
              name="kecamatan"
              rules={[{ required: true, message: "kecamatan is required" }]}
            >
              <Select
                placeholder="Kecamatan"
                disabled={kecamatanList.length < 1}
                onChange={onKecamatanChange}
              >
                {kecamatanList?.map((item, index) => (
                  <Option key={item?.idKecamatan} value={item?.idKecamatan}>
                    {item?.namaKecamatan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kelurahan Tempat Tinggal"
              name="kelurahan"
              rules={[{ required: true, message: "kelurahan is required" }]}
            >
              <Select placeholder="Kecamatan" disabled={kelurahanList.length < 1}>
                {kelurahanList?.map((item, index) => (
                  <Option key={item?.idKelurahan} value={item?.idKelurahan}>
                    {item?.namaKelurahan}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="RT Tempat Tinggal" name="rt">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="RW Tempat Tinggal" name="rw">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
            <Form.Item label="Alamat Tempat Tinggal" name="alamat">
              <Input placeholder="input placeholder" disabled={disable} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Agama"
              name="agama"
              rules={[{ required: true, message: "Agama is required" }]}
            >
              <Select disabled={disable}>
                {registerParam["religion"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Pendidikan Terakhir" name="pendidikan_terakhir">
              <Select disabled={disable}>
                {registerParam["educationLevel"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Status Perkawinan" name="status_perkawinan">
              <Select disabled={disable}>
                {registerParam["maritalStatus"].map((data) => (
                  <Option value={data.value} key={data.value}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Foto KTP" name="foto_ktp">
              {merchantDetail?.picktp && disable ? (
                <Image src={merchantDetail.picktp} />
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  disabled={disable}
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col>
            <a
              onClick={() => {
                setDisable(!disable);
              }}
            >
              Edit
            </a>
          </Col>
        </Row>

        <Row justify="end">
          {disable ? (
            <>
              <Button onClick={onPrevious}>Previous</Button>
              <Button htmlType="button" onClick={onClickedNext} className="ml-3">
                Next
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => { setDisable(!disable);}}>Cancel</Button>
              <Button htmlType="submit" className="ml-3">Save</Button>
            </>
          )}
        </Row>

        {/** Pending Modal */}
        <Modal
            open={pendingModal}
            onOk={showPendingModal}
            onCancel={showPendingModal}
            footer={null}
            style={{ borderRadius: "999rem" }}
          >
            <div style={{
                display: "flex",
                marginBottom: "1rem",
                justifyContent: "center",
              }}>
                <Form layout="vertical" onFinish={showPendingModal}>
                  <Row gutter={45}>
                    <img src={warning} style={{ margin:"auto", marginBottom: "1rem",marginTop:"1rem"}} />
        
                    {/* <p align="center">Pending Merchant Approval</p> */}
                    <p align="center"><span style={{marginBottom: "1rem",marginTop:"1rem", fontWeight:"bold"}}>Pending Merchant Approval</span><br /><br />This Merchant is still waiting for approval. To edit status, please wait until approval process is done.</p>
                    <Button variant="danger" htmlType="submit" style={{ marginTop:"1rem", width:"25%",margin:"auto", borderRadius: "18px"}}>Close</Button>

                  </Row>
                </Form>
            </div>
          </Modal>
          {/* End of Modal Action */}
      </Form>
    </Spin>
  );
};

export default Tab2;
