import React from 'react'
import {Row, Col, Form} from 'react-bootstrap'

const Filter = ({
    path,
    createdAt,
    setcreatedAt,
    lastUpdate,
    setlastUpdate,
    approvalStatus,
    setapprovalStatus
}) => {
    return (
        <>
        {path === "member" && (
            <Row>
            <Col>
            <Form.Group controlId="filterCreatedAt">
                <Form.Label>Created At</Form.Label>
                <Form.Control type="date"  value={createdAt || ''} onChange={e => setcreatedAt(e.target.value)} />
            </Form.Group>
            </Col>
            <Col>
            <Form.Group controlId="filterLastUpdate">
                <Form.Label>Last Update</Form.Label>
                <Form.Control type="date" value={lastUpdate || ''} onChange={e => setlastUpdate(e.target.value)} />
            </Form.Group>
            </Col>
            <Col sm={4}>
                <Form.Group controlId="filterStatusApproval">
                    <Form.Label>Status Approve Mitra</Form.Label>
                    <Form.Control as="select" value={approvalStatus || ''} onChange={e => setapprovalStatus(e.target.value)}>
                        <option value="0">All</option>
                        <option value="1">Aktif</option>
                        <option value="2">Non Aktif</option>
                        <option value="3">Menunggu Approval</option>
                        <option value="4">New</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            </Row>
        )}
        {path === 'logs' && (
            <Row>
                <Col>
                <Form.Group controlId="filterCreatedAt">
                    <Form.Label>Created At</Form.Label>
                    <Form.Control type="date"  value={createdAt || ''} onChange={e => setcreatedAt(e.target.value)} />
                </Form.Group>
                </Col>
            </Row>
        )}
        </>
    )
}

export default Filter