import styled from "@emotion/styled";
import colors, { button } from "./theme";

export const StyledButton = styled("button")`
  ${button.default};
  position: relative;
  height: 38px;
  min-width: 130px;
  padding: 0;

  &:focus {
    z-index: 2;
    box-shadow: ${colors.blue[200]} 0px 0px 0px 3px;
    outline: none;
    text-decoration: underline;
  }
`;

export const StyledInputButton = styled(StyledButton)`
  height: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const StyledFileInputWrapper = styled("div")`
  display: flex;

  input[type="file"] {
    display: none;
  }
`;

export const StyledInput = styled("input")`
  flex: 1;
  font-family: "Helvetica";
  font-size: 14px;
  color: ${colors.grey[800]};
  height: 30px;
  min-width: 250px;
  padding: 0 8px;
  border-style: none;
  box-shadow: ${colors.grey[200]} 0px 0px 0px 1px inset,
    ${colors.grey[200]} 0px 0px 0px 0px;

  flex: 1;
  border-radius: 4px 0 0 4px;
  margin-right: -1px;
  ::placeholder {
    color: ${colors.grey[800]};
  }

  &:focus {
    -webkit-appearance: none;
    background-color: white;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    z-index: 2;
    box-shadow: ${colors.blue[600]} 0px 0px 0px 1px inset,
      ${colors.blue[200]} 0px 0px 0px 3px;
    outline: none;
  }
`;
