import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'

const Searchbar = ({searchData,setSearchData}) => {
    // const [query, setQuery] = useState("");
  
    // useEffect(() => {
    //   const timeOutId = setTimeout(() => setSearchData(query), 500);
    //   return () => clearTimeout(timeOutId);
    // }, [query]);

    return (
        <span>
            <Form.Group controlId="filter">
                <Form.Label style={{fontWeight: "normal"}}>Search: </Form.Label>
                <Form.Control type="text" placeholder= "" value={searchData || ''} onChange={e => setSearchData(e.target.value)}/>
            </Form.Group>
        </span>
    )
}

export default Searchbar