import {
    Button,
    Form,
    Input,
    Checkbox,
    Col,
    Row,
    Space,
    Select,
    message,
    Upload,
    TimePicker,
    Modal,
    Spin,
    
} from "antd";

import { filePickerAuAh, RowAuAh } from "../../components";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useFilePicker } from 'use-file-picker';
import React, { useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { useMerchant } from "../../hooks/useMerchant";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { useCookies } from 'react-cookie';
import FilePickerAuAh from "../../components/filePickerAuAh";
import { faSignOutAlt, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import {UploadOutlined} from '@ant-design/icons'


const { Option, OptGroup } = Select;

const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onload = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };

const Tab4 = ({ registerParam, onPrevious }) => {
    const [spin, setSpin] = useState(false);
    const [cookies] = useCookies(['udata']);
    const [loading, setLoading] = useState(false);
    const { userId, username } = cookies.udata;
    const history = useHistory()
    const [akta, setAkta] = useState();
    const [kumham, setKumham] = useState();
    const [siup, setSiup] = useState();
    const [skdu, setSkdu] = useState();
    const [tdp, setTdp] = useState();
    const [sppkp, setSppkp] = useState();




    const [form] = Form.useForm();

    const UploadButton = (
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      );

    const handleChange = (info, category) => {
        try {
            console.log(info.file);
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        getBase64(info.file.originFileObj, (base64Data) => {
            setLoading(false);
            switch(category){
              case "akta":
                setAkta(base64Data)
                break;
              case "kumham":
                setKumham(base64Data)
                break;
              case "siup":
                setSiup(base64Data)
                break;
              case "skdu":
                setSkdu(base64Data)
                break;
              case "tdp":
                setTdp(base64Data)
                break;
              case "sppkp":
                setSppkp(base64Data)
                break;
            }
          });
        }

        catch(err) {
            console.log("UPLOAD ERROR",err);
        }
        
    };


    const beforeUpload = (file, category) => {
        const isPdf = file.type === "application/pdf";
    
        if (!isPdf) {
          message.error("You can only upload PDF !");
          return false;
        }
    
        return true;
    
      };

    const [pendingModal, setPendingModal] = useState(false);

    const { state, dispatch } = useMerchant();

    const showPendingModal = () => {
        setPendingModal(!pendingModal);
    };
    const onFinish = async (value) => {
        setSpin(true)
        try {
            console.log('HAHAHHAA',akta)
            const { dataTab3, pic_data, primary_data } = state;
            console.log()
            let payload = {
                merchantName: primary_data.nama_usaha,
                merchantNpwp: primary_data.npwp_pemilik,
                businessType: primary_data.businessType,
                ownerName: primary_data.nama_pemilik,
                ownerPhone: "0" + primary_data.nomor_hp,
                ownerEmail: primary_data.email,
                ownerBankId: dataTab3.bank,
                ownerBankAccount: dataTab3.accountBank,
                ownerBankAccountName: dataTab3.bankAccountName,
                picData: {
                    picName: pic_data.nama_pic,
                    picPhone: "0" + pic_data.nomor_hp,
                    merchantPicOwnershipStatus: pic_data.status_kepemilikan,
                    noktp: pic_data.nomor_ktp,
                    birthOfPlace: pic_data.tempat_lahir,
                    dateOfBirth: pic_data.tgl_lahir,
                    province: pic_data.provinsi,
                    city: pic_data.kabupaten,
                    district: pic_data.kecamatan,
                    suburb: pic_data.kelurahan,
                    rt: pic_data.rt,
                    rw: pic_data.rw,
                    address: pic_data.alamat,
                    religion: pic_data.agama,
                    educationLevel: pic_data.pendidikan_terakhir,
                    maritalStatus: pic_data.status_perkawinan,
                    ktp: pic_data.foto_ktp,
                },
                merchantData: {
                    merchantName: dataTab3.nama_usaha,
                    merchantLocationCategory: dataTab3.jenis_usaha,
                    merchanLocationSubcategory: dataTab3.tipe_usaha,
                    merchantOwnershipStatus: dataTab3.kepemilikan_tempat_usaha,
                    numberOfEmployee: dataTab3.jumlah_karyawan,
                    merchantEstablished: dataTab3.lama_usaha,
                    averageDailyRevenue: dataTab3.daily_revenue,
                    averageDailyProfit: dataTab3.daily_profit,
                    bestSellingProduct: dataTab3.top_product,
                    operationalDays: dataTab3.hari_operasional,
                    operationalOpenTime: dataTab3.jam_buka,
                    operationalCloseTime: dataTab3.jam_tutup,
                    province: dataTab3.provinsi,
                    city: dataTab3.kabupaten,
                    suburb: dataTab3.kelurahan,
                    district: dataTab3.kecamatan,
                    address: dataTab3.alamat,
                    bankCode: dataTab3.bank,
                    bankAccountName: dataTab3.bankAccountName,
                    bankAccountNumber: dataTab3.accountBank,
                    rt: dataTab3.rt,
                    rw: dataTab3.rw,
                    merchantEnviromentImage: dataTab3.foto_sekitar,
                    merchantFrontImage: dataTab3.foto_depan,
                    merchantAcquisitionStaffPhoto: dataTab3.foto_petugas,
                    merchantOwnerPhoto: dataTab3.foto_pemilik
                },
                additionalData: {
                    aktaFile: akta,
                    skKumHamFile: kumham,
                    siupFile: siup,
                    skduFile: skdu,
                    tdpFile: tdp,
                    sppkpFile: sppkp
                }
            };
            const register = await axios.post(process.env.REACT_APP_MAIN_API + "/merchants", payload, {
                headers: {
                    username: username,
                    userid: userId
                }
            })

            if (register?.data?.statusCode === "000") {
                Modal.success({
                    title: "Success",
                    content: register?.data?.message || 'Pendaftaran Berhasil',
                })
                history.push('/home')
            } else {
                Modal.error({
                    title: "Error",
                    content: register?.data?.message || 'Register Error !',
                });
            }
            // console.log(payload)
        } catch (err) {
            console.error(err);
            Modal.error({
                title: "Error",
                content: err?.message || 'Please Contact Administrator !',
            });

        } finally {
            setSpin(false)
        }
    };

    return (
        <Form onFinish={onFinish} form={form}>
            <Row gutter={80}>
                <Col span={10}>
                    <Form.Item
                    label="Akta Pendirian atau Anggaran Dasar"
                    name="akta"
                    >
                    <Upload
                         name="akta"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'akta')}
                         onChange={(e) => handleChange(e, 'akta')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>
                <Col span={10}>
                    <Form.Item
                    label="Pengesahan Menteri Hukum dan HAM"
                    name="kumham"
                    >
                    <Upload
                         name="kumham"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'kumham')}
                         onChange={(e) => handleChange(e, 'kumham')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>
                <Col span={10}>
                    <Form.Item
                    label="Surat Ijin Usaha Perdagangan"
                    name="siup"
                    >
                    <Upload
                         name="siup"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'siup')}
                         onChange={(e) => handleChange(e, 'siup')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>
                <Col span={10}>
                    <Form.Item
                    label="Surat Keterangan Domisili Usaha"
                    name="skdu"
                    >
                    <Upload
                         name="skdu"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'skdu')}
                         onChange={(e) => handleChange(e, 'skdu')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>
                <Col span={10}>
                    <Form.Item
                    label="Tanda Daftar Perusahaan"
                    name="tdp"
                    >
                    <Upload
                         name="tdp"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'tdp')}
                         onChange={(e) => handleChange(e, 'tdp')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>

                <Col span={10}>
                    <Form.Item
                    label="Surat Pengukuhan Pengusaha Kena Pajak"
                    name="sppkp"
                    >
                    <Upload
                         name="sppkp"
                         showUploadList={true}
                         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                         beforeUpload={(e) => beforeUpload(e, 'sppkp')}
                         onChange={(e) => handleChange(e, 'sppkp')}
                    >
                        {UploadButton}
                    </Upload>
                    </Form.Item>
                   
                     
                </Col>
            </Row>
            <Row justify="end">
                <Button onClick={onPrevious}>Previous</Button>
                <Button htmlType="submit" className="ml-3">
                    Save
                </Button>
            </Row>
        </Form>
    );
};
export default Tab4;