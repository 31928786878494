import React, {
    useRef, 
    useState, 
    useCallback,
    useMemo
} from 'react'
import {
    Row,
    Col,
    Container, 
    Card,
    Button 
} from 'react-bootstrap'
import {
    Topbar, 
    PageTitle, 
    Footer,
    LogsTable,
    Filters,
    Searchbar
} from '../../components'
import {columnsMaker} from '../../utilities'
import Services from '../../services'
import {useCookies} from 'react-cookie'
import { useRequest } from "../../hooks/useRequest";

const Logs = () => {
    
    const { data: logData, isLoading } = useRequest("/merchants?page=1&limit=10");

    // Get userId from cookie
    const [cookies] = useCookies(['udata']);
    const {userId, username} = cookies.udata;

    let now = new Date().toLocaleString('sv').slice(0, 10)
    const [dataSource, setdataSource] = useState([])
    const [searchData, setsearchData] = useState("")
    const [loading, setLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [createdAt, setcreatedAt] = useState("")
    const fetchIdRef = useRef(0)

    const columnsObj = useMemo(() => [
        {
            Header: 'LogID',
            accessor: 'id'
        },
        {
            Header: 'Action',
            accessor: 'action'
        },
        {
            Header: 'Parameters',
            accessor: 'parameter',
            Cell: ({ value }) => {
                return JSON.stringify(value)
            }
        },
        {
            Header: 'Username',
            accessor: 'username'
        },
        {
            Header: 'UserID',
            accessor: 'user_id'
        },
        {
            Header: 'Created At',
            accessor: 'created_at'
        },
    ], [])

    const handleClear = async (e) => {
        e.preventDefault()
        setsearchData("")
        setcreatedAt("")
    }

    const fetchData = useCallback(async ({ searchData, pageSize, pageIndex, createdAt }) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.
        console.log(pageIndex, pageSize)
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current
        let params = {}
        if (pageIndex == '0') {
            params = {
                limit: pageSize,
            }
        } else {
            params = {
                limit: pageSize,
                page: pageIndex + 1,
            }
        }

        if (searchData) {
            params.search = searchData ?? ""
            setcreatedAt("")
        } else if(createdAt){
            setsearchData("")
            delete params.search
            createdAt ? (params.createdAt = createdAt) : (delete params.createdAt)
        } else {
            delete params.search
            delete params.createdAt
        }

        // Set the loading state
        setLoading(true)

        //Fetch Data From API
        try {
            let serverData = logData.data
            let totalData = logData.totalAllData;
            // addColumns(serverData);
            // Only update the data if this is the latest fetch
            if (fetchId === fetchIdRef.current) {
                //Add delay if searching
                if (searchData) {
                    setdataSource([])
                    setTimeout(() => {
                        setdataSource(serverData)
                        setPageCount(Math.ceil(totalData / pageSize))
                        setLoading(false)
                    }, 1000);
                } else {
                    setdataSource(serverData)
                    setPageCount(Math.ceil(totalData / pageSize))
                    setLoading(false)
                }
            }
            console.log(logData)
        } catch (error) {
            console.log(error)
        }
    }, [])


    return (
        <div>
            <div style={{minHeight: '100vh', backgroundColor: 'whiteSmoke'}}>
                <Topbar/>
                <Container style={{minHeight: '100vh', backgroundColor: 'whiteSmoke', marginTop: "2rem"}}>
                <PageTitle title="Audit Logs" canBack={true}/>
                    <Card style={{marginBottom: "2rem"}}>
                        <Card body>
                        <Row>
                            <Col md={4}>
                            <Searchbar searchData={searchData} setSearchData={setsearchData}/>
                            <Filters 
                                createdAt={createdAt} 
                                setcreatedAt={setcreatedAt}
                                path="logs"
                            />
                            <Button variant="danger" onClick={handleClear} style={{marginBottom: "10px"}}>Clear Filters</Button>
                            </Col>
                        </Row>
                        <LogsTable
                            createdAt={createdAt}
                            searchData={searchData}
                            columns={columnsObj}
                            data={dataSource}
                            fetchData={fetchData}
                            loading={loading}
                            pageCount={pageCount}
                        />
                        </Card>
                    </Card>
                </Container>
                <Footer />
            </div>
        </div>
    )
}

export default Logs
