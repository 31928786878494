const colors = {
    blue: {
      1000: "hsla(203, 67%, 4%, 1)",
      900: "hsla(203, 67%, 9%, 1)",
      800: "hsla(203, 67%, 20%, 1)",
      700: "hsla(203, 67%, 31%, 1)",
      600: "hsla(203, 67%, 42%, 1)",
      500: "hsla(203, 67%, 53%, 1)",
      400: "hsla(203, 67%, 64%, 1)",
      300: "hsla(203, 67%, 75%, 1)",
      200: "hsla(203, 67%, 86%, 1)",
      100: "hsla(203, 67%, 97%, 1)"
    },
    green: {
      1000: "hsla(125, 86%, 14%, 1)",
      900: "hsla(125, 73%, 20%, 1)",
      800: "hsla(125, 56%, 29%, 1)",
      700: "hsla(122, 47%, 35%, 1)",
      600: "hsla(122, 39%, 41%, 1)",
      500: "hsla(123, 35%, 51%, 1)",
      400: "hsla(123, 38%, 63%, 1)",
      300: "hsla(122, 42%, 75%, 1)",
      200: "hsla(126, 49%, 84%, 1)",
      100: "hsla(125, 65%, 93%, 1)"
    },
    grey: {
      1000: "hsla(210, 24%, 16%, 1)",
      900: "hsla(209, 20%, 25%, 1)",
      800: "hsla(209, 18%, 30%, 1)",
      700: "hsla(209, 14%, 37%, 1)",
      600: "hsla(211, 12%, 43%, 1)",
      500: "hsla(211, 10%, 53%, 1)",
      400: "hsla(211, 13%, 65%, 1)",
      300: "hsla(210, 16%, 82%, 1)",
      200: "hsla(214, 15%, 91%, 1)",
      100: "hsla(216, 33%, 97%, 1)"
    },
    red: {
      1000: "hsla(360, 92%, 20%, 1)",
      900: "hsla(360, 85%, 25%, 1)",
      800: "hsla(360, 79%, 32%, 1)",
      700: "hsla(360, 72%, 38%, 1)",
      600: "hsla(360, 67%, 44%, 1)",
      500: "hsla(360, 64%, 55%, 1)",
      400: "hsla(360, 71%, 66%, 1)",
      300: "hsla(360, 77%, 78%, 1)",
      200: "hsla(360, 82%, 89%, 1)",
      100: "hsla(360, 100%, 97%, 1)"
    },
    white: "#ffffff",
    yellow: {
      1000: "hsla(44, 100%, 6%, 1)",
      900: "hsla(44, 100%, 16%, 1)",
      800: "hsla(44, 100%, 26%, 1)",
      700: "hsla(44, 100%, 36%, 1)",
      600: "hsla(44, 100%, 46%, 1)",
      500: "hsla(44, 100%, 56%, 1)",
      400: "hsla(44, 100%, 66%, 1)",
      300: "hsla(44, 100%, 76%, 1)",
      200: "hsla(44, 100%, 86%, 1)",
      100: "hsla(44, 100%, 96%, 1)"
    }
  };
  
  export const button = {
    disabled: {
      backgroundColor: colors.grey[100],
      color: colors.grey[400],
      cursor: "not-allowed",
      border: `2px solid ${colors.grey[100]}`,
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "1rem",
      fontFamily: "Helvetica",
      fontWeight: 700,
      borderRadius: "2px"
    },
  
    default: {
      backgroundColor: colors.red[600],
      color: colors.white,
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      border: `1px solid ${colors.red[600]}`,
      fontSize: "1rem",
      fontWeight: 500,
      borderRadius: "8px",
      cursor: "pointer"
    },
  
    default_inverted: {
      color: colors.blue[800],
      backgroundColor: colors.white,
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      border: `2px solid ${colors.blue[800]}`,
      fontSize: "1rem",
      fontFamily: "Helvetica",
      fontWeight: 700,
      borderRadius: "2px",
      cursor: "pointer"
    }
  };
  
  export default colors;
  